export const API_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const ITDB_URL = process.env.REACT_APP_ITDB_URL;
export const GOOGLE_CLIENTID = process.env.REACT_APP_GOOGLE_CLIENTID;
export const MICROSOFT_CLIENTID = process.env.REACT_APP_MICROSOFT_CLIENTID;
export const FACEBOOK_CLIENTID = process.env.REACT_APP_FACEBOOK_CLIENTID;
export const MATOMO_ID = process.env.REACT_APP_MATOMO_ID;
export const AUTH_TOKEN_NAME = 'tchp_token';
export const PRODUCT_NAME = 'TIR carnet holder portal';
export const PRODUCT_CODE = 'TCHP';
export const AUTO_LOGOUT_TIMEOUT =  1000 * 60 * 14; // 14 minutes

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/;
export const TIR_HOLDER_ID_REGEX = /^([A-Z]{3}\/\d{3}\/\d{1,})$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const FONTAWESOME_VARIANT = 'fa-light'

export const OTP_DISABLED = process.env.REACT_APP_OTP_DISABLED || false;
export const OAUTH_ENABLED = process.env.REACT_APP_OAUTH_ENABLED || false;