import React from 'react';
import { Container, Dropdown, Nav, Navbar, NavItem } from "react-bootstrap";
import tchpLogo from "../../assets/images/tchp-logo.png";
import { Link } from "react-router-dom";
import "./TopNavbar.css"
import { useSelector } from "react-redux";
import PrimaryNavItem from "./PrimaryNavItem";
import { useTranslation } from "react-i18next";
import AvatarProfileImage from "./AvatarProfileImage";
import TchpLogo from '../Layout/TCHPLogo';
import { FONTAWESOME_VARIANT } from '../../config';


const TopNavbar = () => {
  const { t } = useTranslation();

  const authState = useSelector((state) => {
    return state.auth;
  });

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="primaryNavItem"
    >
      {children}
    </a>
  ));

  return (
    <Navbar expand="md" id="primaryNav">
      <Container fluid={true} className="p-0 align-items-start">
        <Navbar.Brand as={Link} to="/" className="brandSection p-0">
          <div className="row p-0">
            <div className="d-none d-lg-block">
              <div style={{ minWidth: 350 }} className="p-0">
                <TchpLogo height={57} />
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div style={{ xminWidth: 350 }} className="p-0">
                <img src={tchpLogo} height={52} alt="TCHP" />
              </div>
            </div>
          </div>

        </Navbar.Brand>

        {authState.isLoggedIn && <>

          <Navbar.Toggle aria-controls="main-nav" />
          <Navbar.Collapse id="main-nav">
            <Nav className="ms-auto mt-2 mt-md-0 ">
              <PrimaryNavItem to="/status" label={t('header:lnkStatus')} icon="fa-address-card fa-fw" />
              <PrimaryNavItem to="/associations" label={t('header:lnkAssociations')} icon="fa-users fa-fw" />
              <PrimaryNavItem to="/notifications" label={t('header:lnkNotifications')} icon="fa-bell fa-fw" />
              <PrimaryNavItem to="/contact" label={t('header:lnkContact')} icon="fa-envelope fa-fw" />
              {!authState.advanced ?
                <>
                  {/* TO ENABLE MY TRANSPORTS MENU ITEM, UNCOMMENT THE LINE BELOW */}
                  {/* <PrimaryNavItem to="/transports" label={t('header:lnkMyTransports')} icon="fa-map-location fa-fw" /> */}
                </>
                :
                <NavItem>
                  <Dropdown align='end'>
                    <Dropdown.Toggle as={CustomToggle} id="profileMenu" >
                      <div className="row ">
                        <div className="col-1 col-md-12 text-md-center pt-2 pt-md-0 px-0 ps-3 ps-md-0">
                          <i className={FONTAWESOME_VARIANT + " fa-cog fa-lg"}></i>
                        </div>
                        <div className="col-11 col-md-12 text-md-center pt-2 pt-md-2 ">
                          <p className="linkText ps-1 ps-md-0">{t('header:lnkManagement')}</p>
                        </div>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item disabled={true}> Holder data management</Dropdown.Item>
                      <Link className="dropdown-item link-600 fw-medium" to="/transportEquipment">
                        <i className={FONTAWESOME_VARIANT + " fa-truck-container text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageTransport')}
                      </Link>
                      <Link className="dropdown-item link-600 fw-medium" to="/address">
                        <i className={FONTAWESOME_VARIANT + " fa-address-book text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageAddresses')}
                      </Link>
                      <Link className="dropdown-item link-600 fw-medium" to="/customsOffice">
                        <i className={FONTAWESOME_VARIANT + " fa-stamp text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageCustomsOffices')}
                      </Link>
                      <Link className="dropdown-item link-600 fw-medium" to="/agent">
                        <i className={FONTAWESOME_VARIANT + " fa-people-arrows-left-right text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageAgents')}
                      </Link>
                      <Link className="dropdown-item link-600 fw-medium" to="/consignee">
                        <i className={FONTAWESOME_VARIANT + " fa-person-carry-box text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageConsignees')}
                      </Link>
                      <Link className="dropdown-item link-600 fw-medium" to="/consignor">
                        <i className={FONTAWESOME_VARIANT + " fa-truck-fast text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageConsignors')}
                      </Link>
                      <Link className="dropdown-item link-600 fw-medium" to="/subcontractor">
                        <i className={FONTAWESOME_VARIANT + " fa-person-dolly text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageSubcontractors')}
                      </Link>

                      <Link className="dropdown-item link-600 fw-medium" to="/driver">
                        <i className={FONTAWESOME_VARIANT + " fa-users text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageDrivers')}
                      </Link>
                      <Link className="dropdown-item link-600 fw-medium" to="/attachment">
                        <i className={FONTAWESOME_VARIANT + " fa-paperclip text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageAttachments')}
                      </Link>
                      <Dropdown.Item disabled={true}>{t('header:lnkTransportManagement')}</Dropdown.Item>
                      {authState.advanced &&
                        <Link className="dropdown-item link-600 fw-medium" to="/tirManagement">
                          <i className={FONTAWESOME_VARIANT + " fa-route text-black-50 me-2 fa-fw"}></i> {t('header:lnkTirManagement')}
                        </Link>
                      }

                      {(authState.canEditeCMR || authState.canVieweCMR) &&
                        <Link className="dropdown-item link-600 fw-medium" to="/eCMR">
                          <i className={FONTAWESOME_VARIANT + " fa-file-contract text-black-50 me-2 fa-fw"}></i> {t('header:lnkCMRManagement')}
                        </Link>
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </NavItem>
              }
              <NavItem>
                <Dropdown align='end'>
                  <Dropdown.Toggle as={CustomToggle} id="profileMenu" >
                    <div className="row ">
                      <div className="col-1 col-md-12 text-md-center pt-2 pt-md-0 px-0 ps-3 ps-md-0">
                        <AvatarProfileImage />
                      </div>
                      <div className="col-11 col-md-12 text-md-center pt-2 pt-md-2 ">
                        <p className="linkText ps-1 ps-md-0">{t('header:lnkAccount')}</p>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-right">
                    <Link className="dropdown-item link-600 fw-medium" to="/account">
                      <i className={FONTAWESOME_VARIANT + " fa-user-cog text-black-50 me-2 fa-fw"}></i> {t('header:lnkManageAccount')}
                    </Link>
                    <Link className="dropdown-item link-600 fw-medium" to="/logout">
                      <i className={FONTAWESOME_VARIANT + " fa-sign-out-alt text-black-50 me-2 fa-fw"}></i> {t('header:lnkLogout')}
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </>
        }
      </Container>
    </Navbar>
  );
};

export default TopNavbar;