import React, { useCallback, useEffect, useState } from 'react';
import TopNavbar from "../../components/navigation/TopNavbar";
import axiosInstance from "../../components/network/ApiCall";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../components/widgets/LoadingSpinner";
import HolderStatus from "../../components/HolderStatus/HolderStatus";
import ErrorAlert from "../../components/widgets/ErrorAlert";
import ModalQr from "../../components/dashboard/ModalQR";
import { NavLink } from "react-router-dom";
import './Dashboard.css';
import ModalExclusionDetails from "../../components/dashboard/ModalExclusionDetails";
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const { t } = useTranslation();

  const [showQRModal, setShowQRModal] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [holderData, setHolderData] = useState()

  const authState = useSelector((state) => {
    return state.auth;
  });

  const getHolderData = useCallback(() => {
    setLoading(true)
    const url = "/holderForUser";
    axiosInstance.get(url)
      .then(response => {
        if (response.data)
          setHolderData(response.data)
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setError(t('common:msgError'))
      })
  }, [t])

  useEffect(() => {
    getHolderData();
  }, [getHolderData])

  const triggerShowDetailsModal = () => {
    setShowDetailsModal(true)
  }

  const triggerShowQRModal = () => {
    setShowQRModal(true)
  }
  const handleClose = () => {
    setShowQRModal(false)
    setShowDetailsModal(false)
  }

  return (
    <>
      <div id="divDashboard" className="m-2 m-sm-5">
        <TopNavbar />
        {loading ? <LoadingSpinner /> :
          <>
            {
              error ? <ErrorAlert error={error} /> :
                <>
                  {holderData?.holder?.authorization ?
                    <HolderStatus currentStatus={holderData.holder.authorization.currentStatus}
                      showDetailsModal={triggerShowDetailsModal}
                      showQRModal={triggerShowQRModal}
                      activeExclusions={holderData.holder.authorization.activeExclusion}
                    />
                    : <div className="mt-4 alert alert-danger">{t('dashboard:msgApiWarning')}</div>}

                  <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-4">
                    <div className="col">
                      <div className="card dashcard mt-5">
                        <NavLink to="/status" className="text-center">
                          <div className="statuscard card-img-top">&nbsp;</div>
                        </NavLink>
                        <div className="card-body ">
                          <h5 className="card-title">{t('dashboard:crdStatus:heading')}</h5>
                          <p className="card-text">{t('dashboard:crdStatus:subHeading')}</p>
                          <NavLink to="/status" className="btn btn-primary">{t('dashboard:crdStatus:btnShow')}</NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="card dashcard mt-5">
                        <NavLink to="/associations" className="text-center">
                          <div className="associationscard card-img-top">&nbsp;</div>
                        </NavLink>
                        <div className="card-body dashcard">
                          <h5 className="card-title">{t('dashboard:crdAssociations:heading')}</h5>
                          <p className="card-text">{t('dashboard:crdAssociations:subHeading')}</p>
                          <NavLink to="/associations" className="btn btn-primary">{t('dashboard:crdAssociations:btnShow')}</NavLink>
                        </div>
                      </div>
                    </div>

                    {authState.advanced &&
                      <div className="col">
                        <div className="card dashcard mt-5">
                          <NavLink to="/tirManagement" className="text-center">
                            <div className="transportscard card-img-top">&nbsp;</div>
                          </NavLink>
                          <div className="card-body dashcard">
                            <h5 className="card-title">{t('dashboard:crdTransports:heading')}</h5>
                            <p className="card-text">{t('dashboard:crdTransports:subHeading')}</p>
                            <NavLink to="/tirManagement" className="btn btn-primary">{t('dashboard:crdTransports:btnShow')}</NavLink>
                          </div>
                        </div>
                      </div>
                    }

                    <div className="col">
                      <div className="card dashcard mt-5">
                        <NavLink to="/notifications" className="text-center">
                          <div className="notificationscard card-img-top">&nbsp;</div>
                        </NavLink>
                        <div className="card-body dashcard">
                          <h5 className="card-title">{t('dashboard:crdNotifications:heading')}</h5>
                          <p className="card-text">{t('dashboard:crdNotifications:subHeading')}</p>
                          <NavLink to="/notifications" className="btn btn-primary">{t('dashboard:crdNotifications:btnShow')}</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                </>
            }
          </>
        }
      </div>
      {holderData?.holder ?
        <>
          {showQRModal && <ModalQr holderId={holderData.holder.code}
            showModal={showQRModal}
            handleClose={handleClose} />}

          {showDetailsModal && <ModalExclusionDetails holderData={holderData.holder}
            showModal={showDetailsModal}
            handleClose={handleClose} />}
        </>
        : null}
    </>

  );
};

export default Dashboard;