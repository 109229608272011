import React, { useRef, useState } from 'react';
import LoadingSpinner from "../../widgets/LoadingSpinner";
import { useLocation } from "react-router-dom";
import { Redirect, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as ACTIONS from "../../../store/actions";

// Receive code from Facebook oauth request and attempt to exchange for token
// depending on result, either login or forward to oauth registration page if no user exists
const FacebookCallback = props => {
  
  const authenticating = useRef(false)

  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = new URLSearchParams(useLocation().search)
  const [code] = useState(queryParams.get('code'))

  const authState = useSelector((state) => {
    return state.auth;
  });
  
  if (authState.isLoggedIn)
    return <Redirect to="/" />;
  else {
    if (authState.loading){
      
      return <LoadingSpinner />;
    }



    if (authState.registrationRequired) {
      if (!authState.emailAddress) {
        console.warn("REG REQUIRED BUT NO EMAIL ADDRESS PROVIDED")
      } else {
        return <Redirect to={{
          pathname: '/oauthRegister',
          state: {
            firstName: authState.firstName,
            lastName: authState.lastName,
            emailAddress: authState.emailAddress,
            profilePhotoUrl: authState.profilePhotoUrl,
            providerId: authState.providerId,
            providerName: "FACEBOOK"
          }
        }} />
      }
    } else if (authState.error) {
      console.error("error with authentication, dispatching error", authState.error)

      history.push('/auth/login', { error: authState.error })
    } else {
      if (code && !authenticating.current)  {
        authenticating.current = true;        
        dispatch(ACTIONS.facebookAuth(code));
      }
    }
    return <LoadingSpinner />;
  }
};

export default FacebookCallback;