import axios from "axios";
import { API_URL, AUTH_TOKEN_NAME } from "../../config";

const authData = JSON.parse(localStorage.getItem(AUTH_TOKEN_NAME));

// give 5 minute time out
const axiosInstance = axios.create({
  baseURL: API_URL + '/api',
  timeout: 300000,
  headers: {
    'Authorization': `Bearer ${authData?.token}`,
    'Content-Type': 'application/json',
    'accept': 'application/json'
  }
});


export function setDefaultHeader(key, value) {
  axiosInstance.defaults.headers.common[key] = value;
}

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    const authData = JSON.parse(localStorage.getItem(AUTH_TOKEN_NAME));
    config.headers = {
      Authorization: `Bearer ${authData?.token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(function (response) {

  return response;
}, function (error) {
  const originalRequest = error.config;  
  // Prevent infinite loops
  if (!error.response || error.response.status === 500) {
    console.error("RESPONSE NOT AVAILABLE. POSSIBLE SERVER CONNECTION ISSUE ", error)
    window.location.href = '/error';
    return Promise.reject(error);
  }

  if (error.response?.status === 401 && originalRequest.url === API_URL + '/auth/refreshtoken') {    
    window.location.href = '/auth';
    return Promise.reject(error);
  }
  
  if (error.response?.status === 401) {
    const authData = JSON.parse(localStorage.getItem(AUTH_TOKEN_NAME));    
    const refreshToken = authData?.refreshToken;

    if (refreshToken) {

      return axiosInstance({
        url: API_URL + '/auth/refreshtoken',
        method: 'post',
        data: { 'grant_type': "refresh_token", "refreshToken": refreshToken }
      })

        .then((response) => {
          if (response?.data) {            
            localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(response.data))

            axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
            originalRequest.headers['Authorization'] = `Bearer ${response.data.token}`;

            return axiosInstance(originalRequest);
          } else {            
            window.location.href = '/logout';
          }
        })
        .catch(err => {
          console.warn("COULD NOT REFRESH RETURNING TO LOGIN", err)
          localStorage.removeItem(AUTH_TOKEN_NAME)
          window.location.href = '/auth/login';
        });

    } else {
      console.warn("Refresh token not available.")
      localStorage.removeItem(AUTH_TOKEN_NAME)
      window.location.href = '/auth/login';
    }
  }
  // specific error handling done elsewhere
  return Promise.reject(error);
}
);

export default axiosInstance;

