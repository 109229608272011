import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

const ErrorAlert = props => {

  
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 20 seconds
    const timeId = setTimeout(() => {       
      if (! props.persistant)
        setAlert(false);

    }, 20 * 1000);
    
    return () => {
      clearTimeout(timeId)
    }
  }, [props]);  

  useEffect(() => {
    setAlert(true)
  }, [props.error])

  return props.error && alert ? (
    <div className="alert alert-danger" role="alert">{props.error}</div>
  ) : null
  
};

ErrorAlert.propTypes = {
  error: PropTypes.string
};
export default ErrorAlert;