import React, {useEffect, useState} from 'react';
import ErrorAlert from "../../../components/widgets/ErrorAlert";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../../../config";
import {Redirect} from "react-router";
import TopNavbar from "../../../components/navigation/TopNavbar";
import {OauthRegisterFormDef} from "../../../components/register/OauthRegisterFormDef";
import OauthRegisterForm from "../../../components/register/OauthRegisterForm";

const OauthRegister = (props) => {
  const {t} = useTranslation();
  
  const [createState, setCreateState] = useState({
    submitting: false,
    registerForm: {
      ...OauthRegisterFormDef,
    },
    errorMessage: null,
    submitted: null,
    successMessage: ''
  });

  useEffect(() => {
    // clearAuthState();
    const updatedForm = {...OauthRegisterFormDef}
    updatedForm.firstName.value = props.location.state.firstName
    updatedForm.firstName.valid = true
    updatedForm.firstName.touched = true
    updatedForm.lastName.value = props.location.state.lastName
    updatedForm.lastName.valid = true
    updatedForm.lastName.touched = true
    setCreateState({...OauthRegisterFormDef,registerForm:updatedForm})
  }, [props.location.state])

  const attemptRegistration = formData => {
    setCreateState({...createState, submitting: true})
    const postData = {
      ...formData,
      profilePhotoUrl: props.location.state.profilePhotoUrl,
      providerId: props.location.state.providerId,
      providerName: props.location.state.providerName,
      emailAddress: props.location.state.emailAddress,
    }
    axios.post(API_URL + "/oauth/register", postData)
      .then(response => {

        if (response.data.userId) {
          setCreateState({
            ...createState,
            errorMessage: null,
            submitted: true,
            successMessage: t('register:msgSuccess')
          })
        } else if (response.data.error !== '')
          setCreateState({...createState, errorMessage: response.data.error, submitting: false})
        else
          setCreateState({...createState, errorMessage: t('common:msgError'), submitting: false})
      })
      .catch(error => {
        if (error?.response?.data?.error !== undefined) {
          setCreateState({
            ...createState,
            errorMessage: t('register:oauthErrors:' + error.response.data.error),
            submitting: false
          })
        } else {
          setCreateState({...createState, errorMessage: t('common:msgError'), submitting: false})
        }
      })

  }
  if (createState.submitted) {
    return <Redirect to={{pathname: "/auth/login", state: {message: createState.successMessage}}}/>;
  }


  return (
    <div id="divRegister" className="m-5">
      <TopNavbar/>
      <div className="card my-5">
        <div className="card-header ">
          <h1>{t('register:frmHeading')}</h1>
        </div>
        <div className="card-body ">
          <ErrorAlert error={createState.errorMessage}/>

          <div className="row ">

            <OauthRegisterForm
              submit={attemptRegistration}
              submitting={createState.submitting}
              defaultForm={createState.registerForm}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OauthRegister;