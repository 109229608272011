import * as actionTypes from './actionTypes'
import axios from 'axios'
import { API_URL, AUTH_TOKEN_NAME, PRODUCT_CODE } from '../../config'
import { setDefaultHeader } from '../../components/network/ApiCall'
import jwt_decode from "jwt-decode";
import { setLanguage } from './language';

export const authClear = () => {
  return {
    type: actionTypes.AUTH_CLEAR
  }
}

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (tokenData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    tokenData: tokenData,
  }
}

export const updateAccountDetails = (accountDetails) => {
  return {
    type: actionTypes.SET_ACCOUNT_DETAILS,
    details: accountDetails,
  }
}

export const authRegistrationRequired = (formData) => {
  return {
    type: actionTypes.AUTH_REG_REQUIRED,
    data: formData,
  }
}

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  }
}


export const logout = () => {  
  localStorage.removeItem(AUTH_TOKEN_NAME)
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const clearAuthState = () => {
  return dispatch => {
    dispatch(authClear())
  }
}

export const checkAuthState = () => {
  return dispatch => {
    const tokenData = localStorage.getItem(AUTH_TOKEN_NAME)    
    if (!tokenData) {
      dispatch(logout())
    } else {
      dispatch(authSuccess(JSON.parse(tokenData)))
    }
  }
}

export const setAuthError = (errorMessage) => {
  return dispatch => {    
    dispatch(authFail(errorMessage));
  }
}

export const msAuth = (params) => {  
  return {
    type: actionTypes.MS_AUTH,
    params: params
  }
}

export const updateMFAState = () => {
  return dispatch => {
    const tokenData = localStorage.getItem(AUTH_TOKEN_NAME)
    
    if (tokenData) {    
      const parsedToken = JSON.parse(tokenData)            
      parsedToken.completed2FA = true      
      localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(parsedToken))
      dispatch(mfaSuccess())
    }
  }
}

export const auth = (username, password, isSignUp) => {
  return dispatch => {
    dispatch(authStart())

    const params = {
      emailAddress: username,
      password: password,
      app: PRODUCT_CODE,
    }

    const url = API_URL + "/auth/login"

    axios.post(url, params)
      .then(response => {
        const tokenData = jwt_decode(response.data.token);

        localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(response.data))
        setDefaultHeader('Authorization', `Bearer ${response.data.token}`)

        if (tokenData.language)
          dispatch(setLanguage(tokenData.language))

        dispatch(authSuccess(response.data))
      })
      .catch(error => {
        console.error("Error  ", error.response)
        if (error.response?.status === 401) {
          dispatch(authFail('auth:msgInvalidCredentials'));
        } else if (error?.response?.data?.error !== undefined)
          dispatch(authFail(error.response.data.error))
        else
          dispatch(authFail('auth:msgOtherFailure'))
      })
  }
}

export const googleAuth = (code) => {
  return dispatch => {
    dispatch(authStart())

    const params = {
      code: code,
      app: PRODUCT_CODE,
    }

    const url = API_URL + "/oauth/googleAuth"

    axios.post(url, params)
      .then(response => {
        if (response.data) {

          if (response.data.registrationRequired === true) {
            dispatch(authRegistrationRequired(response.data))
          } else {
            localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(response.data))
            setDefaultHeader('Authorization', `Bearer ${response.data.access_token}`)
            dispatch(authSuccess(response.data))
          }
        } else if (response.data.result === 'FAILED') {
          dispatch(authFail('auth:msgOtherFailure'))
        }
      })
      .catch(error => {
        console.error("ER  ", error.response)
        if (error.response?.data?.message) {
          dispatch(authFail('auth:error:' + error.response?.data?.message));
        } else
          dispatch(authFail('auth:msgOtherFailure'))
      })
  }
}

export const microsoftAuth = (code) => {
  return dispatch => {
    dispatch(authStart())

    const params = {
      code: code,
      app: PRODUCT_CODE,
    }

    const url = API_URL + "/oauth/microsoftAuth"

    axios.post(url, params)
      .then(response => {
        if (response.data) {

          if (response.data.registrationRequired === true) {
            dispatch(authRegistrationRequired(response.data))
          } else {
            localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(response.data))
            setDefaultHeader('Authorization', `Bearer ${response.data.access_token}`)
            dispatch(authSuccess(response.data))
          }
        } else if (response.data.result === 'FAILED') {
          dispatch(authFail('auth:msgOtherFailure'))
        }
      })
      .catch(error => {
        if (error.response?.data?.message) {
          dispatch(authFail('auth:error:' + error.response?.data?.message));
        } else
          dispatch(authFail('auth:msgOtherFailure'))
      })
  }
}
export const facebookAuth = (code) => {
  return dispatch => {
    dispatch(authStart())

    const params = {
      code: code,
      app: PRODUCT_CODE,
    }

    const url = API_URL + "/oauth/facebookAuth"

    axios.post(url, params)
      .then(response => {
        if (response.data) {

          if (response.data.registrationRequired === true) {
            dispatch(authRegistrationRequired(response.data))
          } else {
            localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(response.data))
            setDefaultHeader('Authorization', `Bearer ${response.data.access_token}`)
            dispatch(authSuccess(response.data))
          }
        } else if (response.data.result === 'FAILED') {
          dispatch(authFail('auth:msgOtherFailure'))
        }
      })
      .catch(error => {
        console.error("ER  ", error.response)

        if (error.response?.data?.message) {
          dispatch(authFail('auth:error:' + error.response?.data?.message));
        } else
          dispatch(authFail('auth:msgOtherFailure'))

        // if (error.response?.status === 401) {
        //   if (error.response.data.message === 'NOTACTIVE')
        //     dispatch(authFail('auth:msgPending'));
        //   else if (error.response.data.message === 'NOTPERMITTED')
        //     dispatch(authFail('auth:msgNotPermitted'));
        //   else
        //     dispatch(authFail('auth:msgInvalidCredentials'));
        // } else if (error.response?.data?.message === "User is disabled")
        //   dispatch(authFail('auth:msgPending'))
        // else if (error?.response?.data?.error !== undefined)
        //   dispatch(authFail(error.response.data.error))
        // else
        //   dispatch(authFail('auth:msgOtherFailure'))
      })
  }

}


export const mfaSuccess = () => {
  return {
    type: actionTypes.MFA_SUCCESS
  }
}

export const verifyMFA = () => {
  return dispatch => {    
    dispatch(mfaSuccess())
  }
}