import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

const SuccessAlert = (props) => {

  const [alert, setAlert] = useState(true);

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 20 seconds
    const timeId = setTimeout(() => {       
      if (! props.persistant)
        setAlert(false);

    }, 20 * 1000);
    
    return () => {
      clearTimeout(timeId)
    }
  }, [props]);  

  useEffect(() => {
    setAlert(true)
  }, [props.message])

  return props.message && alert ? (
    <div className="alert alert-success" role="alert">{props.message}</div>
  ) : null
};

SuccessAlert.propTypes = {  
  message: PropTypes.string,
  persistant: PropTypes.any
};

export default SuccessAlert;  