export const AUTH_CLEAR = 'AUTH_CLEAR'
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_REG_REQUIRED = 'AUTH_REG_REQUIRED'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS'

export const MS_AUTH = 'MS_AUTH'

export const LANGUAGE_SWITCH = 'LANGUAGE_SWITCH'

export const MFA_SUCCESS = 'MFA_SUCCESS'
