import React, {useState} from 'react';
import {APP_URL, GOOGLE_CLIENTID} from "../../../config";
import GoogleLogin from "react-google-login";
import * as ACTIONS from "../../../store/actions";
import {Redirect} from "react-router";
import {useDispatch} from "react-redux";

const GoogleButton = () => {
  const dispatch = useDispatch();

  const [googleCode, setGoogleCode] = useState(null);

  const responseGoogle = (response) => {    
    if (response.code) {
      //history.push('/auth/googleCallback?code=' + response.code)
      setGoogleCode(response.code)
    }
  }

  if (googleCode) {
    dispatch(ACTIONS.clearAuthState())
    return <Redirect to={'/auth/googleCallback?code=' + googleCode}/>
  }
  const redirectUrl = APP_URL + "/auth/googleCallback"

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENTID}      
      buttonText="Login with Google"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      responseType="code"
      redirectUri={redirectUrl}
    />
  );
};

export default GoogleButton;