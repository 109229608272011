import React from 'react';
import { useSelector } from "react-redux";
import { FONTAWESOME_VARIANT } from '../../config';
import './AvatarProfileImage.css'

const AvatarProfileImage = () => {
  const authState = useSelector((state) => {
    return state.auth;
  });

  return <>
    {authState.data?.profilePhotoUrl ?
      <img className="avatarProfileImage" alt="profile" src={authState.data?.profilePhotoUrl} />
      :
      <i className={FONTAWESOME_VARIANT + " fa-user-circle fa-lg"}></i>
    }
  </>;
};

export default AvatarProfileImage;