import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../config";
import axios from "axios";
import ErrorAlert from "../widgets/ErrorAlert";
import LoadingSpinner from "../widgets/LoadingSpinner";
import { checkValidity } from "../../utilities/FormHelpers";
import CustomFormInput from "../forms/CustomFormInput";
import { FormLabel } from "react-bootstrap";
import { ForgotPasswordFormDef } from "./ForgotPasswordFormDef";

const ForgotPassword = (props) => {

  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(props.isValid || false);
  const [form, setForm] = useState({ ...ForgotPasswordFormDef });

  const authState = useSelector((state) => {
    return state.auth;
  });

  const [forgotPasswordState, setForgotPasswordState] = useState({
    error: null,
    linkSent: false,
    sendingRequest: false
  });

  // const [form, setForm] = useState({
  //   emailAddress: "",
  //   invalid: false,
  // });

  const requestPassword = () => {

    setForgotPasswordState({ error: null, linkSent: false, sendingRequest: true, })

    const params = {
      emailAddress: form.emailAddress.value,
    }

    const url = API_URL + "/auth/forgotPassword"

    axios.post(url, params)
      .then(response => {
        setForgotPasswordState({ error: null, linkSent: true, sendingRequest: false, })
      })
      .catch(error => {
        if (error?.response?.data?.error !== undefined)
          setForgotPasswordState({ error: error?.response?.data?.error, linkSent: false, sendingRequest: false })
        else
          setForgotPasswordState({ error: t('common:somethingWentWrong'), linkSent: false, sendingRequest: false })
      })

  }

  const updateFormField = (value, inputIdentifier) => {
    const updatedForm = {
      ...form,
    }

    const updatedFormElement = {
      ...updatedForm[inputIdentifier],
    }
    updatedFormElement.value = value

    let validationErrors = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      form,
      t
    );
    updatedFormElement.error = validationErrors;
    updatedFormElement.valid = !validationErrors;

    updatedFormElement.touched = true
    updatedForm[inputIdentifier] = updatedFormElement

    let formIsValid = true
    for (let inputs in updatedForm) {
      formIsValid = updatedForm[inputs].valid && formIsValid
    }

    setForm(updatedForm)
    setIsValid(formIsValid)
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    const newValue = event.target.value;
    updateFormField(newValue, inputIdentifier)
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (form.emailAddress)
      requestPassword();
  };

  if (authState.isLoggedIn)
    return <Redirect to="/" />;
  else {

    let formContent = <form className="mt-4" onSubmit={handleSubmit}>
      <FormLabel className="fw-bold" for="emailAddress">{t('auth:forgotPassword:fldEmailAddress')}<span
        className="requiredIndicator">*</span> </FormLabel>

      <CustomFormInput
        id="emailAddress"
        fieldDefs={form.emailAddress}
        changed={event => inputChangedHandler(event, 'emailAddress')}
      />
      {/* <input autoFocus={true} className="form-control" required
        type="email" name="emailAddress" placeholder={t('auth:forgotPassword:fldEmailAddress') + ' *'}
        onChange={onChange} /> */}
      <div className="mb-3">
      <button id="btnSubmitForgotPassword" color="primary" className="btn btn-success  mt-3 me-2"
              disabled={!isValid || props.submitting}>
              {t('common:btnSubmit')}
            </button>

        {/* <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" id="btnSubmitForgotPassword"
          disabled={form.emailAddress === '' || forgotPasswordState.sendingRequest}>{t('common:btnSubmit')}</button> */}
        {forgotPasswordState.sendingRequest ?
          <LoadingSpinner /> : ''}
      </div>
    </form>;

    if (forgotPasswordState.linkSent)
      formContent = <div className="mt-4 alert alert-success">{t('auth:forgotPassword:msgCheckEmail')}</div>

    return (
      <>
        <h2>{t('auth:forgotPassword:heading')}</h2>
        <small>{t('auth:forgotPassword:subHeading')}</small>


        <section className="py-0 mt-3">
          <div className="row flex-center">
            <div className="col">

              <div id="crdForgotPassword">
                <ErrorAlert error={t(forgotPasswordState.error)} />

                {formContent}

                <Link className="btn btn-outline-secondary d-block mt-5" to='/auth/login' id="lnkAuth">
                  {t('common:btnBack')}
                </Link>

              </div>
            </div>
          </div>
        </section>


      </>

    );
  }
};

export default ForgotPassword;
