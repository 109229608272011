export const ForgotPasswordFormDef = {
  
  emailAddress: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      autoFocus: true,
    },
    value: '',
    validation: {
      email: true,
      required: true,
      min: 2,
      max: 100
    },
    valid: false,
    touched: false,
  }, 


};