import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import thunk from 'redux-thunk'
import authReducer from './store/reducers/auth'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import AppWithRouter from './App';
import './i18n';
import languageReducer from './store/reducers/language';
import { initMessageListener } from 'redux-state-sync';
import { createStateSyncMiddleware } from 'redux-state-sync';

const rootReducer = combineReducers({
  auth: authReducer,  
  language: languageReducer
})

const reduxStateSyncConfig = {};
const store = createStore(rootReducer, applyMiddleware(thunk, createStateSyncMiddleware(reduxStateSyncConfig)))
initMessageListener(store)

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
      <AppWithRouter />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
