import React, {useCallback, useEffect, useState} from 'react';
import ErrorAlert from "../../../components/widgets/ErrorAlert";
import {RegisterFormDef} from "../../../components/register/RegisterFormDef";
import RegisterForm from "../../../components/register/RegisterForm";
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../../config";
import {Redirect} from "react-router";
import * as ACTIONS from '../../../store/actions/index'
import {useDispatch} from "react-redux";
import TopNavbar from "../../../components/navigation/TopNavbar";

const Register = () => {

  const {t} = useTranslation();

  const dispatch = useDispatch();
  const clearAuthState = useCallback(() => dispatch(ACTIONS.clearAuthState()), [dispatch])
  
  const [createState, setCreateState] = useState({
    submitting: false,
    registerForm: {
      ...RegisterFormDef,
    },
    errorMessage: null,
    submitted: null,
    successMessage: ''
  });

  // for invited users, we can preset the tir holder code field
  const queryParams = new URLSearchParams(useLocation().search)
  const holderCode = queryParams.get('hid')
    if (holderCode) {
      createState.registerForm.tirCarnetHolderCode.value = holderCode
      createState.registerForm.tirCarnetHolderCode.touched = true
      createState.registerForm.tirCarnetHolderCode.valid = true
      createState.registerForm.tirCarnetHolderCode.elementConfig.readOnly="readOnly"
    }


  useEffect(() => {
    clearAuthState();      
  }, [clearAuthState])

  const attemptRegistration = formData => {
    setCreateState({...createState, submitting: true})

    axios.post(API_URL + "/auth/register", formData)
      .then(response => {
        
        if (response.data.userId) {
          setCreateState({
            ...createState,
            errorMessage: null,
            submitted: true,
            successMessage: t('register:msgSuccess')
          })
        } else if (response.data.error !== '')
          setCreateState({...createState, errorMessage: response.data.error, submitting: false})
        else
          setCreateState({...createState, errorMessage: t('common:msgError'), submitting: false})
      })
      .catch(error => {
        if (error?.response?.data?.error !== undefined) {
          setCreateState({
            ...createState,
            errorMessage: t('register:errors:' + error.response.data.error),
            submitting: false
          })
        } else {
          setCreateState({...createState, errorMessage: t('common:msgError'), submitting: false})
        }
      })

  }
  if (createState.submitted) 
    return <Redirect to={{pathname: "/auth/login", state: {message: createState.successMessage}}}/>;
  
  return (
    <div id="divRegister" className="m-2 m-sm-5">
      <TopNavbar/>
      <div className="card my-5">
        <div className="card-header ">
          <h1>{t('register:frmHeading')}</h1>
        </div>
        <div className="card-body ">
          <ErrorAlert error={createState.errorMessage}/>

          <div className="row ">

            <RegisterForm
              submit={attemptRegistration}
              submitting={createState.submitting}
              defaultForm={createState.registerForm}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;