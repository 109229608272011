import React, {useRef, useState} from 'react';

import {API_URL} from '../../config';
import axios from 'axios';
import ErrorAlert from '../widgets/ErrorAlert';
import LoadingSpinner from '../widgets/LoadingSpinner';
import SuccessAlert from '../widgets/SuccessAlert';
import {useTranslation} from 'react-i18next';


const VerifyAccount = (props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [codeAccepted, setCodeAccepted] = useState(false);
  const [error, setError] = useState(false);
  const code = props.match.params.code;
  const userId = parseInt(props.match.params.userId);
  const checkingCode = useRef(false);

  const checkCode = () => {
    checkingCode.current = true;
    if (!code)
      setError('nocode')
    else {
      const url = API_URL + "/auth/verifyEmail"
      axios.post(url, {code: code, userId: userId})
        .then(response => {
          if (response.data === 'OK')
            setCodeAccepted(true)
          else if (response.data.message) {
            setError(t('auth:activation:errors:' + response.data.message))
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          setCodeAccepted(false);
          if (error?.response?.data?.message)
            setError(t('auth:activation:errors:' + error.response.data.message))
          else
            setError(t('common:msgError'))
        })
    }
  };

  if (!checkingCode.current)
    checkCode();

  let sectionContent = <LoadingSpinner/>;

  if (loading) {
    sectionContent = <LoadingSpinner/>
  } else if (error)
    sectionContent = <>
      <ErrorAlert error={error}/>

      <div className="mt-5 d-grid">
        {t('common:msgCloseWindow')}
      </div>
    </>;
  else if (codeAccepted)
    sectionContent = <>
      <SuccessAlert message={t('auth:verification:msgSuccess')} persistant/>
    </>;


  return (
    <>
      <h2 className="mb-5">{t('auth:verification:heading')}</h2>

      {sectionContent}

    </>
  );
};


export default VerifyAccount;