import React from 'react';
import './Footer.css';
import uneceLogo from '../../../assets/images/unece-logo-bw.png';
import itdbLogo from '../../../assets/images/itdb-logo-bw.png';
import eTirLogo from '../../../assets/images/etir-logo-bw.png';
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t } = useTranslation();
  const includeEnvironmentDetail = true;

  return (
<>
    <footer className="m-xs-2 m-sm-5 mb-xs-5 pt-4">
      <div className="row align-items-center row-cols-3 row-cols-md-6">
        <div className="col text-left"><a href="https://unece.org/" target="_blank" rel="noreferrer"><img src={uneceLogo} alt={t('footer:imgAltUnece')}
          className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
        <div className="col text-center"><a href="https://etir.org/" target="_blank" rel="noreferrer"><img src={eTirLogo}  alt={t('footer:imgAltETir')}
          className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
        <div className="col text-center"><a href="https://itdb.unece.org/" target="_blank" rel="noreferrer"><img src={itdbLogo}  alt={t('footer:imgAltITDB')}
          className="img-fluid" style={{ maxHeight: 50 }} /></a>
        </div>
        <div className="col text-center"><a href="/contact">{t('footer:lnkContact')}</a></div>
        <div className="col text-center">
          <LanguageSwitcher />
        </div>
        <div className="col text-center">
          <a href="https://www.unece.org/legal_notice/copyrightnotice.html"
            target="_blank" rel="noreferrer">
            <strong>&copy;</strong>{t('footer:lnkCopyright')}
          </a>
        </div>
      </div>
      <div className="row align-items-center ">
        <div className="col text-start text-black">
          <div
            className="text-left text-black-50 small mt-5">Version: {process.env.REACT_APP_VERSION} {includeEnvironmentDetail ? process.env.NODE_ENV : null}</div>
        </div>
      </div>

    </footer>
</>

  );
};

export default Footer;