import { EMAIL_REGEX } from "../config";

export const checkResetFormValidity = (form, t) => {
  for (let inputs in form) {
    let element = form[inputs];

    let validationErrors = checkValidity(
      element.value,
      element.validation,
      form,
      t
    );
    element.error = validationErrors;
    element.valid = !validationErrors;

    element.touched = true
  }
  ;

  let formIsValid = true
  for (let inputs in form) {
    formIsValid = form[inputs].valid && formIsValid
  }
  return formIsValid;
};

export const checkValidity = (value, rules, form, translations) => {
  
  let isValid = true
  let errorMessage = null;

  if (!rules) return null

  if (rules.required && typeof value === 'string') {
    isValid = value?.trim() !== ''
    if (!isValid)
      errorMessage = translations('validationErrors:REQUIRED');
  }
  if (rules.min && isValid) {
    isValid = !value || value.trim().length >= rules.min;
    if (!isValid)
      errorMessage = translations('validationErrors:MIN_LENGTH', {size: rules.min});
  }

  if (value && rules.max && isValid) {
    isValid = value.trim().length < rules.max;
    if (!isValid)
      errorMessage = translations('validationErrors:MAX_LENGTH', {size: rules.max})
  }

  if (rules.email && isValid) {
    isValid = EMAIL_REGEX.test(value.trim());
    if (!isValid)
      errorMessage = translations('validationErrors:VALID_EMAIL');
  }
  if (rules.match && isValid) {
    isValid = value?.trim() === form[rules.match].value?.trim()
    if (!isValid)
      errorMessage = translations('validationErrors:NOMATCH');
  }
  if (rules.regex && isValid) {

    isValid = rules.regex.test(value.trim())
    if (!isValid)
      errorMessage = translations('validationErrors:REQUIREMENTS');
  }


  return errorMessage
}
