import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkValidity } from '../../utilities/FormHelpers';
import { Col, Form, FormLabel } from 'react-bootstrap';
import CustomFormInput from "../forms/CustomFormInput";
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../store/actions/language';

const RegisterForm = (props) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // State  
  const [isValid, setIsValid] = useState(props.isValid || false);
  const [form, setForm] = useState({ ...props.defaultForm });

  const updateFormField = (value, inputIdentifier) => {
    const updatedForm = {
      ...form,
    }

    const updatedFormElement = {
      ...updatedForm[inputIdentifier],
    }
    updatedFormElement.value = value

    let validationErrors = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      form,
      t
    );
    updatedFormElement.error = validationErrors;
    updatedFormElement.valid = !validationErrors;

    updatedFormElement.touched = true
    updatedForm[inputIdentifier] = updatedFormElement

    let formIsValid = true
    for (let inputs in updatedForm) {
      formIsValid = updatedForm[inputs].valid && formIsValid
    }

    setForm(updatedForm)
    setIsValid(formIsValid)
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    const newValue = event.target.value;
    updateFormField(newValue, inputIdentifier)
  }

  const languageChangedHandler = (event, inputIdentifier) => {
    const newValue = event.target.value;
    
    updateFormField(newValue, inputIdentifier)

    dispatch(setLanguage(newValue));
  }

  const maskedChangedHandler = (event, inputIdentifier) => {
    const newValue = event.target.value.replaceAll('_','');
    updateFormField(newValue, inputIdentifier)
  }

  // Form Submit Handler
  const handleSubmit = e => {
    e.preventDefault();

    const formData = {}
    for (let formElement in form) {
      formData[formElement] = form[formElement].value?.trim()
    }

    props.submit(formData);
  };

  return (
    <>

      <Form onSubmit={handleSubmit} id="frmRegister">

        <p>{t('register:frmSubHeading')}</p>

        <div className="row">

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="emailAddress">{t('register:fldEmailAddress')}<span
              className="requiredIndicator">*</span> </FormLabel>

            <CustomFormInput
              id="emailAddress"
              fieldDefs={form.emailAddress}
              changed={event => inputChangedHandler(event, 'emailAddress')}
            />
            <small>{t('register:hlpEmailAddress')}</small>

          </div>

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="confirmEmailAddress">{t('register:fldConfirmEmailAddress')}<span
              className="requiredIndicator">*</span> </FormLabel>

            <CustomFormInput
              id="confirmEmailAddress"
              fieldDefs={form.confirmEmailAddress}
              changed={event => inputChangedHandler(event, 'confirmEmailAddress')}
            />

          </div>


        </div>

        <div className="row">

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="password">{t('register:fldPassword')}<span
              className="requiredIndicator">*</span>
            </FormLabel>

            <CustomFormInput
              id="password"
              fieldDefs={form.password}
              changed={event => inputChangedHandler(event, 'password')}
            />
            <small>{t('register:hlpPassword')}</small>
          </div>

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="confirmPassword">{t('register:fldConfirmPassword')}<span
              className="requiredIndicator">*</span> </FormLabel>

            <CustomFormInput
              id="confirmPassword"
              fieldDefs={form.confirmPassword}
              changed={event => inputChangedHandler(event, 'confirmPassword')}
            />
          </div>
        </div>


        <div className="row">

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="firstName">{t('register:fldFirstName')}<span
              className="requiredIndicator">*</span></FormLabel>
            <CustomFormInput
              id="firstName"
              fieldDefs={form.firstName}
              changed={event => inputChangedHandler(event, 'firstName')}
            />

          </div>


          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="lastName">{t('register:fldLastName')}<span
              className="requiredIndicator">*</span></FormLabel>

            <CustomFormInput
              id="lastName"
              fieldDefs={form.lastName}
              changed={event => inputChangedHandler(event, 'lastName')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="phoneNumber">{t('register:fldPhoneNumber')}</FormLabel>

            <CustomFormInput
              id="phoneNumber"
              fieldDefs={form.phoneNumber}
              changed={event => inputChangedHandler(event, 'phoneNumber')}
            />
          </div>

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="preferredLanguage">{t('register:fldPreferredLanguage')}</FormLabel>
            <CustomFormInput
              id="preferredLanguage"
              fieldDefs={form.preferredLanguage}
              changed={event => languageChangedHandler(event, 'preferredLanguage')}
            />
          </div>
        </div>

        <hr />
        <div className="row">

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold"
              for="tirCarnetHolderCode"><strong>{t('register:fldHolderId')}</strong> <span
                className="requiredIndicator">*</span></FormLabel>

            {/* <ReactInputMask
              className="form-control"
              changed={event => inputChangedHandler(event, 'tirCarnetHolderCode')}
              permanents={[3, 7]}
              beforeMaskedValueChange={(newState) => {
                newState.value = newState.value.toUpperCase();
                return newState;
              }}
              mask="aaa/999/9999999999" 
              maskPlaceholder="_"
              // maskChar=" " 
              // formatChars={{
              //   '9': '[0-9]',
              //   'a': '[A-Z]'
              // }} 
              /> */}

            <CustomFormInput
              id="tirCarnetHolderCode"
              fieldDefs={form.tirCarnetHolderCode}
              changed={event => maskedChangedHandler(event, 'tirCarnetHolderCode')}
            />
            
            <small>{t('register:hlpHolderId')}</small>
          </div>

        </div>


        <p><em>{t('register:msgMandatory')}</em>
        </p>

        <hr />


        <Form.Group className="row">
          <Col>
            <button id="btnSaveUser" color="primary" className="btn btn-success  mt-3 me-2"
              disabled={!isValid || props.submitting}>
              {t('common:btnSubmit')}
            </button>

            <Link to={'/auth/login'} className="btn btn-outline-secondary mt-3">
              {t('common:btnBack')}
            </Link>
          </Col>
        </Form.Group>

      </Form>

    </>
  );
};

RegisterForm.propTypes = {
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  deleteHandler: PropTypes.func,
};

export default RegisterForm;