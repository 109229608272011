import { LANGUAGE_SWITCH } from "../actions/actionTypes";

const languageReducer = (state = { language: '' }, action) => {
  switch (action.type) {
    case LANGUAGE_SWITCH:
      return { ...state, language: action.setLanguage };
    default:      
      return state;
  }
}

export default languageReducer