import React from 'react';
import {APP_URL, FACEBOOK_CLIENTID} from "../../../config";
import facebookButton from "../../../assets/images/facebook_login.png";

const FacebookButton = () => {

  const getFBUrl = () => {
    const redirectUri = APP_URL + "/auth/facebookCallback";

    return "https://www.facebook.com/v12.0/dialog/oauth" +
      "?client_id=" + FACEBOOK_CLIENTID +
      "&redirect_uri=" + redirectUri +
      "&state={a=1}" +
      "&scope=email"
  };

  function handleFBLogin() {
    window.location.href = getFBUrl()
  }

  return (
    <button className="btn btn-link" onClick={handleFBLogin}>
        <img src={facebookButton} width={220} className="ml-auto" alt="Facebook Logo"/>
    </button>
  );
};

export default FacebookButton;