import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import * as ACTIONS from "../../store/actions/index";
import {Col, Form, FormGroup} from 'react-bootstrap';
import withRedirect from '../hoc/withRedirect';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import GoogleButton from "./oauth/GoogleButton";
// import MicrosoftButton from "./oauth/MicrosoftButton";
import FacebookButton from "./oauth/FacebookButton";
import {OAUTH_ENABLED} from "../../config";

const SignIn = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    dispatch(ACTIONS.auth(email, password));

    props.setRedirect(true);
  };

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  return (
    <>
      <h2 className="mb-5">{t('auth:signIn:heading')}</h2>

      <Form onSubmit={handleSubmit} autoComplete="off" id="frmAuth">

        <div className="mb-3">
          <Form.Control id="fldEmail"
                        autoFocus={true}
                        placeholder={t('auth:signIn:fldEmailAddress')}
                        value={email}
                        onChange={({target}) => setEmail(target.value)}
                        type="email"
          />
        </div>
        <div className="mb-3">
          <Form.Control id="fldPass"
                        placeholder={t('auth:signIn:fldPassword')}
                        value={password}
                        onChange={({target}) => setPassword(target.value)}
                        type="password"
          />
        </div>
        <div className="row flex-between-center">
          <Col xs="auto">
            <Link className="fs-6" to='/auth/forgotPassword' id="lnkForgotPassword">
              {t('auth:signIn:lnkForgotPassword')}
            </Link>
          </Col>
        </div>
        <FormGroup className="mt-5">
          <button type="submit" id="btnSignIn" className="btn btn-primary d-block w-100 mt-3"
                  disabled={isDisabled}>
            {t('auth:signIn:btnLogin')}
          </button>
          <Link to="/register" id="btnRegister" className="btn btn-outline-secondary d-block w-100 mt-3"
                disabled={isDisabled}>
            {t('auth:signIn:btnRegister')}
          </Link>
        </FormGroup>
      </Form>
      <br/>
      {OAUTH_ENABLED === 'true' && <>
        <div className="mt-4 row small">
          <div className="col">
            <p className="text-muted ">{t('auth:signIn:msgAlternatively')}</p>
          </div>
        </div>
        <div className="row small">
          <div className="col text-center mt-3">
            <GoogleButton/>
          </div>
          {/* <div className="col  text-center mt-3">
          <MicrosoftButton/>
        </div> */}
          <div className="col  text-center mt-3">
            <FacebookButton/>
          </div>
        </div>
      </>}
    </>
  );
};

export default withRedirect(SignIn);
