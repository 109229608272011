import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import AuthRoutes from '../../routing/AuthRoutes';
import ErrorAlert from "../../components/widgets/ErrorAlert";
import SuccessAlert from "../../components/widgets/SuccessAlert";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TchpLogo from "../../components/Layout/TCHPLogo";

const Home = (props) => {

  const { t } = useTranslation();

  const authState = useSelector((state) => {
    return state.auth;
  });

  if (authState.isLoggedIn)
    return <Redirect to="/" />;
  else {

    let errorMessage = t(authState.error) ?? props.location?.state?.error;
    let otherMessage = props.location?.state?.message;

    return (

      <div className="homecard card m-2 m-sm-5">
        <div className="card-body p-0 m-0">
          <div className="row p-0 m-0">

            <div className="rightcol col-md-7 m-0 p-0 order-md-2">
                <div className="bg-image p-4 h-100">
                  <div className="mt-md-5">
                    <h3>{t('home:infoPanel:line1')}</h3>
                    <h1>{t('home:infoPanel:line2')}</h1>
                    <h2 className="mt-5">{t('home:infoPanel:line3')}</h2>
                  </div>
                </div>
            </div>

            <div className="leftcol col-md-5 m-0 p-4 order-md-1">
              <div className="row justify-content-center row-cols-1 row-cols-sm-1 align-items-center mb-3">
                <div className="col text-center">
                  <Link to="/" className="brandSection homeBrand">
                    <TchpLogo />
                  </Link>
                </div>
              </div>
              <hr className="mx-5 mb-4 mt-4" />

              <ErrorAlert error={errorMessage} />
              <SuccessAlert message={otherMessage} />

              <AuthRoutes props />

              <hr className="m-5" />
            </div>
          </div>
        </div>
      </div>

    );
  }
};

export default Home;