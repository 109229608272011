import React from 'react';
import './flags-sm.css';
import { Dropdown } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../../store/actions/language';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const languageState = useSelector(state => {
    return state.language;
  })


  const switchLanguage = (e) => {

    dispatch(setLanguage(e.target.dataset.language));
  }
  let selectedOption;
  const currentLanguage = languageState.language || 'en';

  if (currentLanguage === 'en')
    selectedOption = <><span className="flag-xs flag-gb"></span> {t('languages:' + currentLanguage)}</>
  else if (currentLanguage === 'fr')
    selectedOption = <><span className="flag-xs flag-fr"></span> {t('languages:' + currentLanguage)}</>
  else if (currentLanguage === 'tr')
    selectedOption = <><span className="flag-xs flag-tr"></span> {t('languages:' + currentLanguage)}</>
  else if (currentLanguage === 'ru')
    selectedOption = <><span className="flag-xs flag-ru"></span> {t('languages:' + currentLanguage)}</>
  else if (currentLanguage === 'uz')
    selectedOption = <><span className="flag-xs flag-uz"></span> {t('languages:' + currentLanguage)}</>

  return (
    <Dropdown drop="up">
      <Dropdown.Toggle variant="" id="selLanguage" className="my-2 mx-2">
        {selectedOption}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-up">
        <li>
          <Dropdown.Item className="dropdown-item" data-language="en" onClick={switchLanguage}>
            <span className="flag-xs flag-gb"></span>  {t('languages:en')}
          </Dropdown.Item>
        </li>
        <li>
          <Dropdown.Item className="dropdown-item" data-language="fr" onClick={switchLanguage}>
            <span className="flag-xs flag-fr"></span> {t('languages:fr')}
          </Dropdown.Item>
        </li>
        <li>
          <Dropdown.Item className="dropdown-item" data-language="tr" onClick={switchLanguage}>
            <span className="flag-xs flag-tr"></span> {t('languages:tr')}
          </Dropdown.Item>
        </li>
        <li>
          <Dropdown.Item className="dropdown-item" data-language="ru" onClick={switchLanguage}>
            <span className="flag-xs flag-ru"></span> {t('languages:ru')}
          </Dropdown.Item>
        </li>
        <li>
          <Dropdown.Item className="dropdown-item" data-language="uz" onClick={switchLanguage}>
            <span className="flag-xs flag-uz"></span> {t('languages:uz')}
          </Dropdown.Item>
        </li>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;