import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router';
import './App.css';
import Footer from './components/Layout/Footer/Footer';
import { Routes } from './routing/routes';
import * as ACTIONS from './store/actions/index'
import { createBrowserHistory } from 'history';
import LoadingSpinner from './components/widgets/LoadingSpinner';
import { setLanguage } from './store/actions/language';
import i18next from "i18next";
import EmergencyContactButton from "./components/contact/EmergencyContactButton";
// import axiosInstance from './components/network/ApiCall';
import { createInstance, MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { MATOMO_ID } from './config';

const App = props => {


  const { enableLinkTracking } = useMatomo()

  enableLinkTracking()

  const dispatch = useDispatch();

  const checkAuthState = useCallback(() => dispatch(ACTIONS.checkAuthState()), [dispatch])

  const logoutAction = useCallback(() => dispatch(ACTIONS.logout()), [dispatch]);

  const authState = useSelector(state => {
    return state.auth
  })

  const languageState = useSelector(state => {
    return state.language
  })

  const registerPageLoad = useCallback(() => {

    var _paq = window._paq || [];

    _paq.push(['setCustomUrl', history.location.pathname]);

    _paq.push(['setDocumentTitle', history.location.pathname]);
    
    if (authState.isLoggedIn) {
      _paq.push(['setUserId', authState.emailAddress]);      
    }

    _paq.push(['trackPageView']);
  },[authState.isLoggedIn, authState.emailAddress]);

  useEffect(() => {
    checkAuthState();
    if (!languageState.language) {
      if (localStorage.getItem('language')) {        
        if (i18next.language !== localStorage.getItem('language'))
          i18next.changeLanguage(localStorage.getItem('language'))
      } else {        
        dispatch(setLanguage('en'))
      }
    }

    return history.listen((location) => {        
      registerPageLoad();
    });

  }, [checkAuthState, languageState.language, dispatch, registerPageLoad])

  const handleLogout = async () => {    
      logoutAction()    
  }

  const childProps = {
    ...authState,
    handleLogout: handleLogout,
  }

  if (authState.loading)
    return <LoadingSpinner />
  else
    return (
      <main className="main container-fluid" id="top">

        <Routes childProps={childProps} />
        <Footer />

        {/* //   </div> */}
        {authState.isLoggedIn ? <EmergencyContactButton /> : null}
      </main>
    );
}

const instance = createInstance({
  urlBase: 'https://webanalytics.unece.org',
  siteId: MATOMO_ID,
  // userId: 'UID76903202', // optional, default value: `undefined`.      
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  // configurations: { // optional, default value: {}
  //   // any valid matomo configuration, all below are optional
  //   disableCookies: true,
  //   setSecureCookie: true,
  //   setRequestMethod: 'POST'
  // }
})

const history = createBrowserHistory();

const AppWithRouter = () => (
  <Router history={history}>
    <MatomoProvider value={instance}>
      <App />
    </MatomoProvider>
  </Router>
)

export default AppWithRouter;
