import {updateObject} from '../../utility'
import * as actionTypes from '../actions/actionTypes'
import jwt_decode from "jwt-decode";

const initialState = {
  data: null,
  token: null,
  refreshToken: null,
  userId: null,
  error: null,
  loading: true,
  isLoggedIn: false,
  tirCarnetHolderCode: null,
  emailAddress: null,
  advanced:  false,
  completed2FA:false,
}

const authClear = (state, action) => {
  return updateObject(state, {error: null, loading: false})
}

const authStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const authSuccess = (state, action) => {
  const tokenData = jwt_decode(action.tokenData.token);
  return updateObject(state, {
    data: action.tokenData,
    token: action.tokenData.token,
    refreshToken: action.tokenData.refreshToken,
    userId: tokenData.id,
    emailAddress: tokenData.emailAddress,
    name: tokenData.name,
    phone: tokenData.phone,
    tirCarnetHolderCode: tokenData.tirCarnetHolderCode,
    preferredLanguage: tokenData.preferredLanguage,
    advanced: tokenData.roles?.includes('ROLE_ADVUSER'),    
    canEditeCMR: tokenData.roles?.includes('ROLE_ECMR'),    
    canVieweCMR: tokenData.roles?.includes('ROLE_ECMR') || tokenData.roles?.includes('ROLE_ECMR_RO') ,    
    isLoggedIn: true,
    error: null,
    loading: false,    
    completed2FA:action.tokenData.completed2FA,
  })
}

const authRegistrationRequired = (state, action) => {
  return updateObject(state, {
    firstName: action.data.firstName,
    lastName: action.data.lastName,
    profilePhotoUrl: action.data.profilePhotoUrl,
    emailAddress: action.data.emailAddress,
    providerId: action.data.providerId,
    providerName: action.data.providerName,
    registrationRequired: true,
    data: action.data,    
    isLoggedIn: false,
    error: null,
    loading: false,
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    isLoggedIn: false
  })
}

const authLogout = (state, action) => {
  return updateObject(initialState, {token: null, userId: null, data: null, isLoggedIn: false, loading: false})
}

const setAccountDetailsState = (state, action) => {
  return updateObject(state, {
    firstName: action.firstName,
    lastName: action.lastName,
    companyName: action.companyName,    
    receiveNotifications: action.receiveNotifications,
    receiveExclusionNotifications: action.receiveExclusionNotifications,
    receiveWithdrawalNotifications: action.receiveExclusionNotifications,
    receiveEoaNotifications: action.receiveEoaNotifications,
  })
}

const verifyMFA = (state, action) => {
  return updateObject(state, {completed2FA: true})
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_CLEAR:
      return authClear(state, action)
    case actionTypes.AUTH_START:
      return authStart(state, action)
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_REG_REQUIRED:
      return authRegistrationRequired(state, action)
    case actionTypes.AUTH_FAIL:
      return authFail(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action)
    case actionTypes.SET_ACCOUNT_DETAILS:
      return setAccountDetailsState(state,action)
    case actionTypes.MFA_SUCCESS:
      return verifyMFA(state, action)  
    default:
      return state
  }
}

export default reducer
