import React from 'react';
import './EmergencyContactButton.css';
import {useHistory} from "react-router";

const EmergencyContactButton = () => {

  const history = useHistory();

  const navigateToContact = () => {
    history.push('/contact?emergency=1')
  }

  return (
    <>
    <button className="emergencyContactButton btn btn-danger flex-grow-1 xd-none xd-sm-block" onClick={navigateToContact}>
      <i className="fa fa-hands-helping me-2"></i>
      Emergency Contact
    </button>

  {/*<button className="btn btn-danger d-xs-block d-sm-none" onClick={navigateToContact}>*/}
  {/*  <i className="fa fa-hands-helping me-2"></i>*/}
  {/*  Emergency Contact*/}
  {/*</button>*/}
    </>
  );
};

export default EmergencyContactButton;