import React, { lazy, Suspense, useRef, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Logout } from '../containers/Auth/Logout/Logout';
import Dashboard from '../containers/Dashboard/Dashboard';
import Home from '../containers/Home/Home';
import Register from "../containers/Auth/Register/Register";
import OauthRegister from "../containers/Auth/Register/OauthRegister";
import { useHistory } from "react-router";
import { useIdleTimer } from 'react-idle-timer' 
import { AUTO_LOGOUT_TIMEOUT, OTP_DISABLED } from "../config";
import SwitchUser from '../containers/Auth/SwitchUser';
import { useTranslation } from 'react-i18next';
import LoadingLogo from '../components/widgets/LoadingLogo';
import LoadingSpinner from '../components/widgets/LoadingSpinner';


const Contact = lazy(() => import('../containers/Contact/Contact'));
const Status = lazy(() => import('../containers/Status/Status'));
const Associations = lazy(() => import('../containers/Associations/Associations'));
const Notifications = lazy(() => import('../containers/Notifications/Notifications'));

const TransportEquipment = lazy(() => import('../containers/TransportEquipment/TransportEquipment'));
const CreateTransportEquipment = lazy(() => import('../containers/TransportEquipment/CreateTransportEquipment'));
const EditTransportEquipment = lazy(() => import('../containers/TransportEquipment/EditTransportEquipment'));
const ShowTransportEquipment = lazy(() => import('../containers/TransportEquipment/ShowTransportEquipment'));

const PreferredAddressList = lazy(() => import('../containers/PreferredAddress/PreferredAddressList'));
const CreateAddress = lazy(() => import('../containers/PreferredAddress/CreateAddress'));
const EditAddress = lazy(() => import('../containers/PreferredAddress/EditAddress'));

const ConsignorList = lazy(() => import('../containers/Consignor/ConsignorList'));
const CreateConsignor = lazy(() => import('../containers/Consignor/CreateConsignor'));
const EditConsignor = lazy(() => import('../containers/Consignor/EditConsignor'));

const ConsigneeList = lazy(() => import('../containers/Consignee/ConsigneeList'));
const CreateConsignee = lazy(() => import('../containers/Consignee/CreateConsignee'));
const EditConsignee = lazy(() => import('../containers/Consignee/EditConsignee'));

const AgentList = lazy(() => import('../containers/Agent/AgentList'));
const CreateAgent = lazy(() => import('../containers/Agent/CreateAgent'));
const EditAgent = lazy(() => import('../containers/Agent/EditAgent'));

const SubcontractorList = lazy(() => import('../containers/Subcontractor/SubcontractorList'));
const CreateSubcontractor = lazy(() => import('../containers/Subcontractor/CreateSubcontractor'));
const EditSubcontractor = lazy(() => import('../containers/Subcontractor/EditSubcontractor'));

const CustomsOfficeList = lazy(() => import('../containers/CustomsOffice/CustomsOfficeList'));
const CreateCustomsOffice = lazy(() => import('../containers/CustomsOffice/CreateCustomsOffice'));
const EditCustomsOffice = lazy(() => import('../containers/CustomsOffice/EditCustomsOffice'));

const DriverList = lazy(() => import('../containers/Driver/DriverList'));
const EditDriver = lazy(() => import('../containers/Driver/EditDriver'));
const ShowDriver = lazy(() => import('../containers/Driver/ShowDriver'));

const AttachmentList = lazy(() => import('../containers/Attachment/AttachmentList'));
const CreateAttachment = lazy(() => import('../containers/Attachment/CreateAttachment'));
const EditAttachment = lazy(() => import('../containers/Attachment/EditAttachment'));

const TirManagement = lazy(() => import('../containers/TirManagement/TirManagement'));
const MyTransports = lazy(() => import('../containers/TirManagement/MyTransports'));
const CreateTirMessage = lazy(() => import('../containers/TirManagement/CreateTirMessage'));
const EditTirMessage = lazy(() => import('../containers/TirManagement/EditTirMessage'));
const AmendAdvanceData = lazy(() => import('../containers/TirManagement/AmendAdvanceData'));
const ShowTransport = lazy(() => import('../containers/TirManagement/ShowTransport'));
const ShowMyTransport = lazy(() => import('../containers/TirManagement/ShowMyTransport'));
const ShowSubmission = lazy(() => import('../containers/TirManagement/ShowSubmission'));

const ECMR = lazy(() => import('../containers/eCMR/ECMR'));
const CreateECMR = lazy(() => import('../containers/eCMR/CreateECMR'));
const EditECMR = lazy(() => import('../containers/eCMR/EditECMR'));

const MFACheck = lazy(() => import('../containers/Auth/MFACheck'));

const Account = lazy(() => import('../containers/Account/Account'));
const Error500 = lazy(() => import('../containers/Errors/Error500'));
const Error404 = lazy(() => import('../containers/Errors/Error404'));


const ProtectedRouteWith2FA = ({ render: C, props: childProps, ...rest }) =>

  <Suspense fallback={<LoadingSpinner />}>
    <Route
      {...rest}
      render={rProps =>
        childProps.isLoggedIn ? (
          (OTP_DISABLED === 'true' || childProps.completed2FA) ?
            (<C {...rProps} {...childProps} />)
            : <MFACheck />
        ) : (
          <Redirect
            to={`/auth/login`}
          />
        )
      }
    />
  </Suspense>


export const ProtectedRoute = ({ render: C, props: childProps, ...rest }) =>

  <Suspense fallback={<LoadingSpinner />}>
    <Route
      {...rest}
      render={rProps =>
        childProps.isLoggedIn ? (
          <C {...rProps} {...childProps} />
        ) : (
          <Redirect
            to={`/auth/login`}
          />
        )
      }
    />
  </Suspense>

export const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
  <>

    <Suspense fallback={<LoadingSpinner />}>
      <Route {...rest} render={rProps => <C {...rProps} {...childProps} />} />
    </Suspense>

  </>
)

export const Routes = ({ childProps }) => {

  const { t } = useTranslation();

  const history = useHistory();
  const idleTimerRef = useRef();

  // Idle timer functions, only used when isLoggedIn == true
  const [idleTimerState, setIdleTimerState] = useState({
    timeout: AUTO_LOGOUT_TIMEOUT,
    showModal: false,
    isTimedOut: false
  })


  const checkLastActivity = () => {
    const currentTime = new Date().getTime()
    const difference = currentTime - idleTimerRef.current.getLastActiveTime()
    const milliSecondsDifference = difference;
    if (milliSecondsDifference >= AUTO_LOGOUT_TIMEOUT) {
      history.push('/logout?to=1')
    }
  }

  // const onAction = (e) => {
  //   // setIdleTimerState({...idleTimerState, isTimedOut: false})    
  //   clearTimeout(idleTimerRef.current)
  // }
  const onActive = (e) => {
    setIdleTimerState({ ...idleTimerState, isTimedOut: false })
    checkLastActivity()
  }
  const onIdle = (e) => {
    const isTimedOut = idleTimerState.isTimedOut
    if (isTimedOut) {
      history.push('/logout?to=1')
    } else {
      setIdleTimerState({ ...idleTimerState, isTimedOut: false })      
      setIdleTimerState({ ...idleTimerState, isTimedOut: true })
    }
  }

  const onAction = (e) => {}

  document.title = t('common:applicationName')

  const idleTimer  = useIdleTimer({
    
    onIdle,
    onActive,
    onAction,
    timeout:AUTO_LOGOUT_TIMEOUT,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: true,
    stopOnIdle: false,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  }) 

  if (childProps.isLoggedIn)
    idleTimer.start();
  else
    idleTimer.pause();

  return (
    <>
      {/* {childProps.isLoggedIn ?
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        // onAction={onAction}
        debounce={250}
        timeout={idleTimerState.timeout}
        events={['mousedown', 'keydown', 'wheel', 'mousemove']}
      />
      : null} */}

      <Switch>
        <ProtectedRoute exact path="/" render={Dashboard} props={childProps} />
        <ProtectedRoute exact path="/status" render={Status} props={childProps} />
        <ProtectedRoute exact path="/associations" render={Associations} props={childProps} />
        <ProtectedRoute exact path="/notifications" render={Notifications} props={childProps} />
        <ProtectedRoute exact path="/account" render={Account} props={childProps} />

        <ProppedRoute exact path="/auth/switchUser/:id/:un" render={SwitchUser} props={childProps} />
        <ProppedRoute path="/auth" render={Home} props={childProps} />
        <ProppedRoute path="/register" render={Register} props={childProps} />
        <ProppedRoute path="/oauthregister" render={OauthRegister} props={childProps} />
        <ProppedRoute exact path="/contact" render={Contact} props={childProps} />

        <ProppedRoute exact path="/logout" render={Logout} props={childProps} />
        <ProppedRoute exact path="/error" render={Error500} props={childProps} />

        {/* Advanced User Routes */}
        <ProtectedRouteWith2FA exact path="/transportEquipment" render={TransportEquipment} props={childProps} />
        <ProtectedRouteWith2FA exact path="/transportEquipment/create" render={CreateTransportEquipment} props={childProps} />
        <ProtectedRouteWith2FA exact path="/transportEquipment/edit/:id" render={EditTransportEquipment} props={childProps} />
        <ProtectedRouteWith2FA exact path="/transportEquipment/:id" render={ShowTransportEquipment} props={childProps} />

        <ProtectedRouteWith2FA exact path="/address" render={PreferredAddressList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/address/create" render={CreateAddress} props={childProps} />
        <ProtectedRouteWith2FA exact path="/address/edit/:id" render={EditAddress} props={childProps} />

        <ProtectedRouteWith2FA exact path="/consignor" render={ConsignorList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/consignor/create" render={CreateConsignor} props={childProps} />
        <ProtectedRouteWith2FA exact path="/consignor/edit/:id" render={EditConsignor} props={childProps} />

        <ProtectedRouteWith2FA exact path="/consignee" render={ConsigneeList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/consignee/create" render={CreateConsignee} props={childProps} />
        <ProtectedRouteWith2FA exact path="/consignee/edit/:id" render={EditConsignee} props={childProps} />

        <ProtectedRouteWith2FA exact path="/agent" render={AgentList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/agent/create" render={CreateAgent} props={childProps} />
        <ProtectedRouteWith2FA exact path="/agent/edit/:id" render={EditAgent} props={childProps} />

        <ProtectedRouteWith2FA exact path="/subcontractor" render={SubcontractorList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/subcontractor/create" render={CreateSubcontractor} props={childProps} />
        <ProtectedRouteWith2FA exact path="/subcontractor/edit/:id" render={EditSubcontractor} props={childProps} />

        <ProtectedRouteWith2FA exact path="/customsOffice" render={CustomsOfficeList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/customsOffice/create" render={CreateCustomsOffice} props={childProps} />
        <ProtectedRouteWith2FA exact path="/customsOffice/edit/:id" render={EditCustomsOffice} props={childProps} />

        <ProtectedRouteWith2FA exact path="/driver" render={DriverList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/driver/edit/:id" render={EditDriver} props={childProps} />
        <ProtectedRouteWith2FA exact path="/driver/:id" render={ShowDriver} props={childProps} />

        <ProtectedRouteWith2FA exact path="/attachment" render={AttachmentList} props={childProps} />
        <ProtectedRouteWith2FA exact path="/attachment/create" render={CreateAttachment} props={childProps} />
        <ProtectedRouteWith2FA exact path="/attachment/edit/:id" render={EditAttachment} props={childProps} />

        <ProtectedRouteWith2FA exact path="/tirManagement" render={TirManagement} props={childProps} />
        <ProtectedRoute exact path="/transports" render={MyTransports} props={childProps} />
        <ProtectedRouteWith2FA exact path="/tirMessage/create" render={CreateTirMessage} props={childProps} />
        <ProtectedRouteWith2FA exact path="/tirDataSubmission/:id" render={ShowSubmission} props={childProps} />
        <ProtectedRouteWith2FA exact path="/tirDataSubmission/edit/:id" render={EditTirMessage} props={childProps} />
        <ProtectedRouteWith2FA exact path="/tirTransport/:id" render={ShowTransport} props={childProps} />
        <ProtectedRouteWith2FA exact path="/tirTransport/amend/:id" render={AmendAdvanceData} props={childProps} />

        <ProtectedRoute exact path="/myTransport/:id" render={ShowMyTransport} props={childProps} />


        <ProtectedRoute exact path="/ecmr" render={ECMR} props={childProps} />

        <ProtectedRoute exact path="/ecmr/create" render={CreateECMR} props={childProps} />
        <ProtectedRoute exact path="/ecmr/edit/:id" render={EditECMR} props={childProps} />


        <ProtectedRoute exact path="/mfaCheck" render={MFACheck} props={childProps} />

        <Suspense fallback={<LoadingLogo />}>
          <Route component={Error404} />
        </Suspense>
      </Switch>
    </>
  )
}