import React, { useState } from 'react';
import { Col, FormGroup, FormLabel, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { checkValidity } from '../../utilities/FormHelpers';
import CustomFormInput from '../forms/CustomFormInput';
import ErrorAlert from '../widgets/ErrorAlert';
import { NewPasswordFormDef } from './NewPasswordFormDef';

const NewPasswordForm = (props) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({ ...NewPasswordFormDef });

  const [, setSubmitting] = useState(false);

  const { submitHandler } = props;

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitting(true);

    const formData = {
      password: form.password.value
    }

    submitHandler(formData);

  };

  // State  
  const [isValid, setIsValid] = useState(false);

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedForm = {
      ...form,
    }
    const updatedFormElement = {
      ...updatedForm[inputIdentifier],
    }
    updatedFormElement.value = event.target.value

    let validationErrors = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      form,
      t
    );
    updatedFormElement.error = validationErrors;
    updatedFormElement.valid = !validationErrors;

    updatedFormElement.touched = true
    updatedForm[inputIdentifier] = updatedFormElement

    let formIsValid = true
    for (let inputs in updatedForm) {
      formIsValid = updatedForm[inputs].valid && formIsValid
    }

    setForm(updatedForm)
    setIsValid(formIsValid)
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Row className="d-flex justify-content-between mb-4">
        <Col xs="auto">
          <h5 className="mb-0">{t('auth:frmSetPassword:frmHeading')}</h5><small>{t('auth:frmSetPassword:frmSubHeading')}</small>
        </Col>
        <Col xs="auto">
          <Link className="fs--1 small" to='/auth/login'>
            {t('auth:frmSetPassword:lnkToLogin')}
          </Link>
        </Col>
      </Row>

      <ErrorAlert error={form.error} />

      <div className="mb-3" >
        <FormLabel className="fw-bold" for="name">{t('auth:frmSetPassword:fldNewPassword')}<span
          className="requiredIndicator">*</span> </FormLabel>
        <InputGroup key="password">
          <InputGroup.Text><i className="fas fa-lock"></i></InputGroup.Text>
          <CustomFormInput
            id="password"
            required="required"
            autoFocus={true}
            autoComplete="off"
            fieldDefs={form.password}
            changed={event => inputChangedHandler(event, 'password')}
          />
        </InputGroup>
      </div>

      <div className="mb-3" >
        <FormLabel className="fw-bold" for="name">{t('auth:frmSetPassword:fldConfirmNewPassword')}<span
          className="requiredIndicator">*</span> </FormLabel>
        <InputGroup key="confirmPassword">
          <InputGroup.Text><i className="fas fa-lock"></i></InputGroup.Text>
          <CustomFormInput
            id="confirmPassword"
            required="required"
            fieldDefs={form.confirmPassword}
            changed={event => inputChangedHandler(event, 'confirmPassword')}
          />
        </InputGroup>
      </div>
      <div className="mb-3">
        <p className="text-muted small">{t('auth:frmSetPassword:passwordConstraints')}</p>
      </div>

      <FormGroup>
        <button className="btn btn-success d-block w-100 mt-3" disabled={!isValid} >
          {t('auth:frmSetPassword:btnUpdatePassword')}
        </button>
      </FormGroup>
    </form >
  );
};

export default NewPasswordForm;