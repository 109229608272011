import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {checkValidity} from '../../utilities/FormHelpers';
import {Col, Form, FormLabel} from 'react-bootstrap';
import CustomFormInput from "../forms/CustomFormInput";
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../store/actions/language';

const OauthRegisterForm = (props) => {

  const {t} = useTranslation();

  const dispatch = useDispatch();

  // State  
  const [isValid, setIsValid] = useState(props.isValid || false);
  const [form, setForm] = useState({...props.defaultForm});

  const updateFormField = (value, inputIdentifier) => {
    const updatedForm = {
      ...form,
    }

    const updatedFormElement = {
      ...updatedForm[inputIdentifier],
    }
    updatedFormElement.value = value;

    let validationErrors = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      form,
      t
    );
    updatedFormElement.error = validationErrors;
    updatedFormElement.valid = !validationErrors;

    updatedFormElement.touched = true
    updatedForm[inputIdentifier] = updatedFormElement

    let formIsValid = true
    for (let inputs in updatedForm) {
      formIsValid = updatedForm[inputs].valid && formIsValid
    }

    setForm(updatedForm)
    setIsValid(formIsValid)
  }

  const languageChangedHandler = (event, inputIdentifier) => {
    const newValue = event.target.value;
    
    updateFormField(newValue, inputIdentifier)

    dispatch(setLanguage(newValue));
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    const newValue = event.target.value;
    updateFormField(newValue.trim(), inputIdentifier)
  }

  // Form Submit Handler
  const handleSubmit = e => {
    e.preventDefault();

    const formData = {}
    for (let formElement in form) {
      formData[formElement] = form[formElement].value?.trim()
    }

    props.submit(formData);
  };

  return (
    <>

      <Form onSubmit={handleSubmit} id="frmRegister">

        <p>{t('register:frmSubHeading')}</p>      

        <div className="row">

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="firstName">{t('register:fldFirstName')}<span
              className="requiredIndicator">*</span></FormLabel>
            <CustomFormInput
              id="firstName"
              fieldDefs={form.firstName}
              changed={event => inputChangedHandler(event, 'firstName')}
            />

          </div>


          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="lastName">{t('register:fldLastName')}<span
              className="requiredIndicator">*</span></FormLabel>

            <CustomFormInput
              id="lastName"
              fieldDefs={form.lastName}
              changed={event => inputChangedHandler(event, 'lastName')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="phoneNumber">{t('register:fldPhoneNumber')}</FormLabel>

            <CustomFormInput
              id="phoneNumber"
              fieldDefs={form.phoneNumber}
              changed={event => inputChangedHandler(event, 'phoneNumber')}
            />
          </div>

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold" for="preferredLanguage">{t('register:fldPreferredLanguage')}</FormLabel>
            <CustomFormInput
              id="preferredLanguage"
              fieldDefs={form.preferredLanguage}
              changed={event => languageChangedHandler(event, 'preferredLanguage')}
            />
          </div>
        </div>

        <hr/>
        <div className="row">

          <div className="col-xs-12 col-md-6 mb-3">
            <FormLabel className="fw-bold"
                       for="tirCarnetHolderCode"><strong>{t('register:fldHolderId')}</strong> <span
              className="requiredIndicator">*</span></FormLabel>
            <CustomFormInput
              id="tirCarnetHolderCode"
              fieldDefs={form.tirCarnetHolderCode}
              changed={event => inputChangedHandler(event, 'tirCarnetHolderCode')}
            />
            <small>{t('register:hlpHolderId')}</small>
          </div>

        </div>


        <p><em>{t('register:msgMandatory')}</em>
        </p>

        <hr/>


        <Form.Group className="row">
          <Col>
            <button id="btnSaveUser" color="primary" className="btn btn-success  mt-3 me-2"
                    disabled={!isValid || props.submitting}>
              {t('common:btnSubmit')}
            </button>

            <Link to={'/auth/login'} className="btn btn-outline-secondary mt-3">
              {t('common:btnBack')}
            </Link>
          </Col>
        </Form.Group>

      </Form>

    </>
  );
};

OauthRegisterForm.propTypes = {
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  deleteHandler: PropTypes.func,
};

export default OauthRegisterForm;