import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config';
import axios from 'axios';
import ErrorAlert from '../widgets/ErrorAlert';
import LoadingSpinner from '../widgets/LoadingSpinner';
import NewPasswordForm from './NewPasswordForm';
import SuccessAlert from '../widgets/SuccessAlert';
import { useTranslation } from 'react-i18next';


const ActivateAccount = (props) => {

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const code = props.match.params.code;
  const userId = parseInt(props.match.params.userId);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const submitPassword = async (formData) => {
    // call to check token    

    const url = API_URL + "/auth/resetPassword"

    axios.post(url, { ...formData, code: code, userId: userId })
      .then(response => {
        if (response.data === 'OK')
          setPasswordChanged(true)
        else if (response.data.message) {
          setError(t('auth:activation:errors:' + error.response.data.message))
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error?.response?.data?.message)
          setError(error.response.data.message)
        else
          setError(t('common:msgError'))
      })


  };

  useEffect(() => {
    // Verify the provided code is valid ( and not expired )
    const checkCode = () => {
      // call to check token    
      if (!code)
        setError('nocode')
      else {
        const url = API_URL + "/auth/checkCode"
        axios.post(url, { code: code, userId: userId })
          .then(response => {
            if (response.data.message) {
              setError(t('auth:activation:errors:' + response.data.message))
            }
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
            if (error?.response?.data?.message)
              setError(t('auth:activation:errors:' + error.response.data.message))
            else
              setError(t('common:msgError'))
          })
      }

    };
    checkCode();
  }, [code, userId, t]);

  let sectionContent = <LoadingSpinner />;

  if (loading) {
    sectionContent = <LoadingSpinner />
  } else if (error)
    sectionContent = <>
      <h2 className="mb-5">{t('common:hdgError')}</h2>
      <ErrorAlert error={error} />
      <div className="mt-5">
        <Link to="/">{t('common:lnkReturnHome')} </Link>
      </div>
    </>;
  else if (passwordChanged)
    sectionContent = <>
      <SuccessAlert message={t('auth:activation:msgSuccess')} persistant />
      <div className="mt-5 d-grid">
        <Link to="/auth/login" className="btn btn-primary">{t('auth:activation:btnLogin')}</Link>
      </div>
    </>;
  else
    sectionContent = <NewPasswordForm submitHandler={submitPassword} />

  return (
    <>
      <h2 className="mb-5">{t('auth:activation:heading')}</h2>

      {sectionContent}

      <div className="m-3">
        <p className="text-muted small">{t('auth:activation:msgAcceptTerms')}<a href="https://unece.org"
          target="_blank" rel="noreferrer"> {t('auth:activation:msgTerms')}.</a></p>
      </div>

    </>
  );
};


export default ActivateAccount;