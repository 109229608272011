import React from 'react';
import i18next from "i18next";

const TchpLogo = (props) => {
  const fileName = "/assets/images/TCHP-logo-fulltext-" + i18next.language + ".png"
  return (
    <img src={fileName} className="img-fluid px-3" alt="TCHP Logo" style={props.height ? { height: props.height } : null} />
  );
};

export default TchpLogo;