import { PASSWORD_REGEX } from "../../config";

export const NewPasswordFormDef = {

  password: {
    elementType: 'input',    
    elementConfig: {
      type: 'password',            
    },
    value: '',
    // placeholderKey:'auth:frmSetPassword:fldNewPassword',
    validation: {
      required: true, 
      regex: PASSWORD_REGEX,
    },
    valid: false,
    touched: false,
  },
  confirmPassword: {
    // placeholderKey:'auth:frmSetPassword:fldConfirmNewPassword',
    elementType: 'input',    
    elementConfig: {
      type: 'password',      
    },
    value: '',
    validation: {
      required: true,
      regex: PASSWORD_REGEX,
      match: 'password'
    },
    valid: false,
    touched: false,
  },

}; 