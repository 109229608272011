import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import PropTypes from "prop-types";
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";

export const Logout = props => {
  const {t} = useTranslation();

  const {handleLogout} = props
  const queryParams = new URLSearchParams(useLocation().search)
  const wasTimeout = queryParams.get('to')

  useEffect(() => {
    handleLogout()
  }, [handleLogout])

  const reason = wasTimeout ? t('auth:msgAutoLogout') : null;

  return <Redirect to={{pathname: "/auth/login", state: {message: reason}}}/>

}

Logout.propTypes = {
  handleLogout: PropTypes.func.isRequired
};
