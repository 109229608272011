import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import enTranslations from './i18n/en.js';
// import frTranslations from './i18n/fr.js';
// import trTranslations from './i18n/tr.js';
// import ruTranslations from './i18n/ru.js';
// import uzTranslations from './i18n/uz.js';

import en from './i18n/en.json';

// the translations

const resources = {
  en: {translation: require('./i18n/en.json')},  
  fr: {translation: require('./i18n/fr.json')},  
  tr: {translation: require('./i18n/tr.json')},  
  ru: {translation: require('./i18n/ru.json')},  
  uz: {translation: require('./i18n/uz.json')},  
};
console.log(resources)
i18n
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    keySeparator: ':', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
     ns: ['translation'],
     defaultNS: 'translation',
    partialBundledLanguages: "true"
  });

export default i18n;