import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import * as ACTIONS from '../../store/actions/index'
import {useDispatch} from "react-redux";

import { setDefaultHeader } from '../../components/network/ApiCall';
import ErrorAlert from '../../components/widgets/ErrorAlert';
import LoadingSpinner from '../../components/widgets/LoadingSpinner';
import { API_URL, AUTH_TOKEN_NAME } from '../../config';



const SwitchUser = props => {
  const uuid = props.match.params.id;
  const username = props.match.params.un;
  const dispatch = useDispatch(); 
  const clearAuthState = useCallback(() => dispatch(ACTIONS.clearAuthState()), [dispatch])

  const [switchState, setSwitchState] = useState({
    success: false,
    errorMessage: null,
    successMessage: '',
    loading: false,
  });


  useEffect(() => {
    const verifyRequest = async () => {
      setSwitchState({
        loading: true,
      });            

      clearAuthState()

      axios.post(API_URL + `/auth/adminSwitch`, {
        un: decodeURI(username),
        id: uuid,
      }).then(response => {

        if (response.data.result === 'SUCCESS') {

          localStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(response.data.token))
          setDefaultHeader('Authorization', `Bearer ${response.data.token.access_token}`)

          setSwitchState({
            loading: false,
            success: true,
          });
        } else
          setSwitchState({
            loading: false,
            errorMessage: 'Could not initialise. Check the account status is ACTIVE.'
          })

      })
        .catch(error => {
          console.error(error)
          setSwitchState({
            loading: false,
            errorMessage: 'Could not initialise.'
          })
        })
    }

    verifyRequest();
  }, [username, uuid, clearAuthState]);

  if (switchState.success)
    window.location.href = "/";

  return (
    <>

      <div className="row">


        <div className="col">
          <div className="d-flex mb-4">
            <div className="col">
              <h4 className="mb-3">
                <span className="pe-3">Authenticating</span>
                <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
              </h4>
            </div>
          </div>


          {switchState.loading ? <LoadingSpinner /> : <>
            {switchState.success ? <>

              Done
            </> : <>

              {switchState.errorMessage ? <ErrorAlert error={switchState.errorMessage} /> : null}
            </>
            }


          </>}

        </div>

      </div>


    </>
  );
}


export default SwitchUser;