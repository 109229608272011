import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router';
import ActivateAccount from '../components/auth/ActivateAccount';
import VerifyAccount from '../components/auth/VerifyAccount';
import SignIn from '../components/auth/SignIn';
import AcceptAccount from "../components/auth/AcceptAccount";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import GoogleCallback from "../components/auth/oauth/GoogleCallback";
import OauthRegister from "../containers/Auth/Register/OauthRegister";
import MicrosoftCallback from "../components/auth/oauth/MicrosoftCallback";
import FacebookCallback from "../components/auth/oauth/FacebookCallback";

const AuthRoutes = props => { 

  const ProppedRoute = ({render: C, props: childProps, ...rest}) => (
    <>

      <Suspense fallback={<div>Loading...</div>}>
        <Route {...rest} render={rProps => <C {...rProps} {...childProps} />}/>
      </Suspense>

    </>
  )

  return (
    <Switch>
      <ProppedRoute exact path="/auth/login" render={SignIn} props={props} />
      <ProppedRoute exact path="/auth/activate/:code/:userId" render={ActivateAccount} props={props}/>
      <ProppedRoute exact path="/auth/verify/:code/:userId" render={VerifyAccount} props={props}/>
      <ProppedRoute exact path="/auth/validate/:code/:userId" render={AcceptAccount} props={props}/>
      <ProppedRoute exact path="/auth/reset/:code/:userId" render={ResetPassword} props={props}/>
      <ProppedRoute exact path="/auth/forgotPassword" render={ForgotPassword} props={props}/>
      <ProppedRoute exact path="/auth/googleCallback" render={GoogleCallback} props={props}/>
      <ProppedRoute exact path="/auth/microsoftCallback" render={MicrosoftCallback} props={props}/>
      <ProppedRoute exact path="/auth/facebookCallback" render={FacebookCallback} props={props}/>
      <ProppedRoute exact path="/auth/oauthRegister" render={OauthRegister} props={props}/>      
    </Switch>
  );
};

export default AuthRoutes;