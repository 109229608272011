import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import './HolderStatus.css';

const HolderStatus = props => {
  const { t } = useTranslation();

  const { currentStatus, showDetailsModal, showQRModal, activeExclusions } = props;

  let alertClass = '';
  let iconClass = '';
  let text = '';
  const hasExclusions = activeExclusions;

  if (!currentStatus)
    return null;

  if (currentStatus === '001') {
    alertClass = 'alert alert-success';
    iconClass = 'fa fa-check-circle fa-2x';
  } else if (currentStatus === '002') {
    alertClass = 'alert alert-danger';
    iconClass = 'fa fa-exclamation-circle fa-2x';
  } else if (currentStatus === '003') {
    alertClass = 'alert alert-secondary';
    iconClass = 'fa fa-times-circle fa-2x';
  } else if (currentStatus === '004') {
    alertClass = 'alert alert-danger';
    iconClass = 'fa fa-check-circle fa-2x';
    // includeExclusionButton = true
  }

  text = <> <span className="fw-normal fs-4"> {t('holderStatus:msgYourStatus')}</span>
    <span className="fw-bold fs-4">{t('holderStatus:' + currentStatus)}.</span>
  </>

  let excludedCountries = [];
  
  if (activeExclusions)
    excludedCountries =
      activeExclusions
      .map((ex, index) => <button key={index} className="btn btn-link fw-normal fs-5 p-0 pb-1" onClick={showDetailsModal}>{t('countries:' + ex.country)}</button>)
      .reduce((prev, curr) => [prev, ', ', curr])
  
  return (
    <>
      <div className={alertClass + ' mt-4'} id="divHolderStatus">
        <div className="row align-items-center">
          <div className="col-auto px-3">
            <i className={iconClass}></i>
          </div>

          <div className="col">{text}</div>

          <div className="col-auto px-3 align-items-start">
            <button type="button" onClick={showQRModal}
              className="btn btn-outline-secondary"><i className="fa-regular fa-qrcode fa-2x"></i></button>
          </div>
        </div>

      </div>

      {hasExclusions ?
        <div className={'alert alert-warning mt-4'} id="divHolderStatus">
          <div className="row align-items-center">
            <div className="col-auto text-center">
              <i className="fa fa-exclamation-triangle fa-2x"></i>
            </div>

            <div className="col fw-normal fs-5">{t('dashboard:msgExclusionsPresent')}
              {excludedCountries}
            </div>
          </div>

        </div>
        : null}
    </>
  );
};

HolderStatus.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  showDetailsModal: PropTypes.func.isRequired,
  showQRModal: PropTypes.func.isRequired,
  activeExclusions: PropTypes.any.isRequired,
};

export default HolderStatus;