import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

const CustomFormInput = props => {
  let inputElement = null;
  let errorText = null;
  let placeholder = '';

  const inputClasses = ['form-control']
  const { t } = useTranslation();

  const fieldDefs = { ...props.fieldDefs }

  if (!fieldDefs.valid && fieldDefs.validation && fieldDefs.touched)
    inputClasses.push('is-invalid')

  if (fieldDefs.valid && fieldDefs.validation && fieldDefs.touched)
    inputClasses.push('is-valid')

  if (fieldDefs.placeholderKey)
    placeholder = t(fieldDefs.placeholderKey)

  switch (fieldDefs.elementType) {  
    case 'file':
      inputElement = (
        <input type="file" onChange={props.changed} 
          
          id={props.id}
          className={inputClasses.join(' ')}
          {...fieldDefs.elementConfig}          
        />
      )
      break
    case 'textarea':
      inputElement = (
        <textArea onChange={props.changed}
          id={props.id}
          className={inputClasses.join(' ')}
          {...fieldDefs.elementConfig}
          value={fieldDefs.value}
        />
      )
      break
    case 'select':
      let options;
      if (props.options)
        options = props.options;
      else
        options = fieldDefs.elementConfig.options;

      inputElement = (
        <select onChange={props.changed}
          id={props.id}
          className={inputClasses.join(' ')}
          {...fieldDefs.elementConfig}
          value={fieldDefs.value}
        >
          {!fieldDefs.elementConfig.skipNull ?
            <option key="" value=""></option>
            : null}
          {options?.map(option => (
            <option key={option.id} value={option.id}>{option.valueKey ? t(option.valueKey) : option.value}</option>
          ))}

        </select>
      )
      break
    case 'switch':
      inputElement = (
        <div className="form-check form-switch ps-0">{fieldDefs.value}
          <input className={inputClasses.join(' ') + ' form-check-input ms-0 ps-0 fs-2'}
            id={props.id}
            onChange={props.changed}
            type="checkbox"  {...fieldDefs.elementConfig}
            checked={fieldDefs.value}
          />
          {fieldDefs.elementConfig?.labelKey ? <label className="form-check-label fs-0 ps-2 pt-1">{t(fieldDefs.elementConfig.labelKey)}</label> : null}
        </div>
      )
      break;
    case 'masked':
      inputElement = (
        <ReactInputMask
          onChange={props.changed}
          id={props.id}
          className={inputClasses.join(' ')}
          {...fieldDefs.elementConfig}
          placeholder={placeholder}
          value={fieldDefs.value}
        />);
        break;
    default:
      inputElement = (
        <input
          onChange={props.changed}
          id={props.id}
          className={inputClasses.join(' ')}
          {...fieldDefs.elementConfig}
          placeholder={placeholder}
          value={fieldDefs.value}
        />
      )

      break
  }

  if (fieldDefs.error)
    errorText = <div className="invalid-feedback">{fieldDefs.error}</div>

  return (
    // <label>{props.label}</label>
    <>
      {inputElement}
      {errorText}
    </>
  )
}

export default CustomFormInput
