import React from 'react';
import { useTranslation } from 'react-i18next';

/* Display an animated loading indicator */

const LoadingSpinner = () => {
  const { t } = useTranslation();

  return (

    <div className="d-flex justify-content-center align-items-center w-100" style={{ minHeight: '300px' }}>
      <div className="spinner-border text-secondary align-self-center" role="status" style={{ width: '3rem', height: '3rem' }}>
        <span className="visually-hidden">{t('common:msgLoading')}</span>
      </div>
    </div>

  );
};

export default LoadingSpinner;