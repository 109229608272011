import React from 'react';
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ModalExclusionDetails = props => {

  const { t } = useTranslation();

  const { showModal, handleClose, holderData } = props;

  if (!holderData.authorization.activeExclusion)
    return null;

  //  Gather exclusion data
  let activeExclusions = null;


  if (holderData.authorization.activeExclusion) {
    activeExclusions = [<div className="row">
      <div className="col"><strong>{t('dashboard:DetailsModal:lblStartDate')}</strong></div>
      <div className="col"><strong>{t('dashboard:DetailsModal:lblEndDate')}</strong></div>
      <div className="col"><strong>{t('dashboard:DetailsModal:lblCountry')}</strong></div>
    </div>]
    activeExclusions.push(holderData.authorization.activeExclusion.map((ex) => {
      return (<div className="row">
        <div className="col">{ex.startDate}</div>
        <div className="col">{ex.endDate}</div>
        <div className="col">{t('countries:' + ex.country)}</div>
      </div>);
    }
    )
    )
  }


  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('dashboard:exclusionsModal:title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div>
          <table className="table table-striped table-warning mx--md-5 mb-2">
            <thead>
              <tr>
                <th className="ps-3">{t('exclusionWithdrawals:lblType')}</th>
                <th >{t('exclusionWithdrawals:lblStartDate')}</th>
                <th>{t('exclusionWithdrawals:lblEndDate')}</th>
                <th>{t('exclusionWithdrawals:lblCountry')}</th>
              </tr>
            </thead>
            <tbody>
              {holderData.authorization.activeExclusion.map((ex, index) => {
                let countryText = t('countries:' + ex.country);
                if (ex.isEuropeanUnion)
                  countryText = t('exclusionWithdrawals:txtCountryInEU', { name: countryText });

                return (<tr key={index}>
                  <td className="col ps-3">{t('exclusionWithdrawals:txt' + ex.type)}</td>
                  <td className="col">{ex.startDate}</td>
                  <td className="col">{ex.endDate ?? 'n/a'}</td>
                  <td className="col">{countryText}</td>
                </tr>);
              }
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('common:btnClose')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalExclusionDetails.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  holderData: PropTypes.object.isRequired
};

export default ModalExclusionDetails;