import React from 'react';
import {Button, Modal} from "react-bootstrap";
import QRCode from "react-qr-code";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import { ITDB_URL } from '../../config';

const ModalQr = props => {
  const {t} = useTranslation();

  const {showModal, handleClose, holderId} = props

  const url = ITDB_URL + "/holders/transporter/searchHolderId?holderID=" + holderId;

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">{t('dashboard:QRModal:title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <p>{t('dashboard:QRModal:msgSubheading', {holderId:holderId})}</p>
        <div className="text-center mt-5">
          <QRCode value={url}/>
          <h2>{holderId}</h2>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('common:btnClose')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalQr.propTypes = {
  showModal:PropTypes.bool.isRequired,
  handleClose:PropTypes.func.isRequired,
  holderId:PropTypes.string.isRequired
};

export default ModalQr;