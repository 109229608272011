import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import './PrimaryNavItem.css';
import {NavItem} from "react-bootstrap";
import { FONTAWESOME_VARIANT } from '../../config';

const PrimaryNavItem = props => {
  const {to, label, icon, badgeCount} = props;

  return (
    <NavItem className="mx-md-1">
      {badgeCount ?
        <span className="ms-1 badge rounded-pill bg-danger navBadge">10</span>
        : null}
    <NavLink to={to} className="primaryNavItem ">
      <div className="row">
        <div className="col-1 col-md-12 text-md-center pt-2 pt-md-0 px-0 ps-3 ps-md-0">
          <i className={FONTAWESOME_VARIANT + " fa-lg " + icon}></i>
        </div>
        <div className="col-11 col-md-12 text-md-center pt-2 pt-md-2 ">
          <p className="linkText ps-1 ps-md-0">{label}</p>
        </div>
      </div>
    </NavLink>
    </NavItem>
  );
};

PrimaryNavItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  badgeCount: PropTypes.number
};

export default PrimaryNavItem;