import React from 'react';
// import { useTranslation } from 'react-i18next';
import loadingImage from '../../assets/images/tchp-loading.gif'
/* Display an animated loading indicator */

const LoadingLogo = () => {
  // const { t } = useTranslation();

  return (

    <div className="d-flex justify-content-center align-items-center w-100" style={{ minHeight: '300px' }}>
      <div className="cspinner-border text-secondary align-self-center" role="status" style={{ width: '6rem', height: '6rem' }}>
        {/* <span className="visually-hidden">{t('common:msgLoading')}</span> */}
        <img src={loadingImage} alt="Loading logo" className='w-100'/>
      </div>
    </div>

  );
};

export default LoadingLogo;