import i18next from 'i18next';
import * as actionTypes from './actionTypes'

export const setLanguage = (language) => {
  i18next.changeLanguage(language);
  localStorage.setItem('language', language);

  return {
    type: actionTypes.LANGUAGE_SWITCH,
    setLanguage: language,
  }
}




