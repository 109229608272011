import {PASSWORD_REGEX, TIR_HOLDER_ID_REGEX} from "../../config";

export const RegisterFormDef = {
  tirCarnetHolderCode: {
    elementType: 'masked',
    elementConfig: {
      mask:"aaa/999/9999999999",
      maskPlaceholder:" ",
      permanents:[3, 7],
      beforeMaskedStateChange: function ({nextState}) {
        nextState.value = nextState.value.toUpperCase();
        return nextState;
      },
      type: 'text',
    },
    value: '',
    validation: {
      required: true,
      regex: TIR_HOLDER_ID_REGEX,
      min: 1,
      max: 50,
    },
    valid: false,
    touched: false,
  },
  firstName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
    },
    value: '',
    validation: {
      required: true,
      min: 2,
      max: 50
    },
    valid: false,
    touched: false,
  },
  lastName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
    },
    value: '',
    validation: {
      required: true,
      min: 2,
      max: 50
    },
    valid: false,
    touched: false,
  },
  emailAddress: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      autoFocus: true,
    },
    value: '',
    validation: {
      email: true,
      required: true,
      min: 2,
      max: 100
    },
    valid: false,
    touched: false,
  },
  confirmEmailAddress: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
    },
    value: '',
    validation: {
      email: true,
      required: true,
      min: 2,
      max: 100,
      match: 'emailAddress'
    },
    valid: false,
    touched: false,
  },
  phoneNumber: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
    },
    validation: {
      required: false,
      max:25
    },
    value: '',
    valid: true,
    touched: false,
  },


  password: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
    },
    value: '',
    validation: {
      required: true,
      regex: PASSWORD_REGEX,
    },
    valid: false,
    touched: false,
  },
  confirmPassword: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
    },
    value: '',
    validation: {
      required: true,
      regex: PASSWORD_REGEX,
      match: 'password'
    },
    valid: false,
    touched: false,
  },
  preferredLanguage: {
    elementType: 'select',
    elementConfig: {
      skipNull: true,
      options: [{ id: 'en', valueKey: 'languages:en' },
      { id: 'fr', valueKey: 'languages:fr' },
      { id: 'tr', valueKey: 'languages:tr' },
      { id: 'ru', valueKey: 'languages:ru' },
      { id: 'uz', valueKey: 'languages:uz' },]
    },
    value: 'en',
    validation: {
      required: true,
    },
    valid: true,
    touched: true,
  },


};